export default {
  imageLoading: '图片加载中...',
  twoPointsTips: '请通过鼠标[左键]绘制,连续点击[两个点]后结束',
  threePointsTips: '请通过鼠标[左键]绘制,至少绘制[两个点]后通过鼠标[右键]结束',
  distance: '长度: ',
  fourPointsTips: '请通过鼠标[左键]绘制,至少绘制[三个点]后通过鼠标[右键]结束',
  area: '面积: ',
  totalDistance: '总长: ',
  imageScale: '缩放比例',
  imageFull: '放大100%',
  postProgress: '光效处理',
  imageInfo: '图片信息',
  imageDownload:'图片下载',
  clearMeasure: '清除测量',
  measureArea: '面积测量',
  measureLine: '直线测量',
  measureRuler: '卡尺测量',
  drawRect: '绘制矩形(快捷键: 1)',
  drawLine: '自由绘制(快捷键: 2)',
  exposure: '曝光',
  construct: '对比度',
  sharpen: '锐化',
  imageNo: '图片编号',
  createTime:'创建时间',
  cameraType: '拍摄设备',
  factory: '厂商',
  rulerTitle: '裂缝宽度测量尺(mm)',
  rulerError: '卡尺初始化异常,请待模型加载完毕后使用该功能',
  downloadOriginalImage:'下载原图',
  downloadDiseaseImage:'下载病害图',
  drawDisableTips:'标记病害，需切换为筛查模式',
  rectDelete:'框选删除(快捷键: 3)'
}
