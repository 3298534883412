export default {
  // 病害类型
  CRACK: 'crack',
  DISEASE_SPALLING: 'spalling ',
  DISEASE_RUST: 'corrosion',
  DISEASE_DEFORM: 'transformation',
  DISEASE_HOLE: 'hollow',
  DISEASE_WORN: 'broken ',
  DISEASE_REVEAL_REIN: 'showing the sinew ',
  DISEASE_BURN_IN: 'aged',
  DISEASE_SHEATH_DAMAGE: 'sheath broken/off ',
  DISEASE_PEELPAINT: 'off/skin paint ',
  DISEASE_BULGING: 'bulging belly ',
  DISEASE_CARBONIZATION: 'Carbonization of concrete',
  DISEASE_WATER_SEEPAGE: 'water seepage ',
  DISEASE_WEBLIKE_CRACK: 'network crack ',
  DISEASE_HONEYCOMB: 'Honeycomb',
  DISEASE_SCRATCH_DAMAGE:'Scratched',
  DISEASE_SCRATCH:'Scratch',
  DISEASE_LASSO_DAMAGE:'Lasso damage',
  DISEASE_HOLLOW_OUT:'Hollow out',


  // 病害字段名
  FIELD_Index: 'number ',
  FIELD_no: 'Disease number ',
  FIELD_type: 'disease type ',
  FIELD_size: 'disease size ',
  FIELD_desc: 'Disease description ',
  FIELD_ImageIndex: 'Picture number',
  FIELD_mark: 'Spatial marker',
  FIELD_scale: 'scale'
}
