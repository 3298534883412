import request from '../axios.js'

export default {
  // 设置消息已读
  setReaded: async(id) => {
    const res = await request({
      url: `/notice/read/${id}`,
      method: 'PUT'
    })
    return res
  },
  setReadedByType: async(type) => {
    const res = await request({
      url: `/notice/read-type/${type}`,
      method: 'PUT'
    })
    return res
  },
  // 查看通知
  getNotice: async(id) => {
    const res = await request({
      url: `/notice/${id}`,
      method: 'GET'
    })
    return res
  },
  // 删除单个通知
  delNoticeById: async(id) => {
    const res = await request({
      url: `/notice/${id}`,
      method: 'GET'
    })
    return res
  },
  // 清空所有通知
  delAllNoticeByType: async(type) => {
    const res = await request({
      url: `/notice/type/${type}`,
      method: 'DELETE'
    })
    return res
  },
  // 获取消息列表
  getNoticeList: async(params) => {
    const res = await request({
      url: '/notice/page',
      method: 'GET',
      params
    })
    return res
  },
  getNoticeTypeStatus: async() => {
    const res = await request({
      url: '/notice/type-status',
      method: 'GET'
    })
    return res
  },
  postNotice: async(data) => {
    const res = await request({
      url: '/notice/send',
      method: 'POST',
      data
    })
    return res
  },
  noticRead(id) {
    return request({
      url: `/notice/read/${id}`,
      method: 'PUT'
    })
  }
}
