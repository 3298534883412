<!--
 * @Author: zhengqj
 * @Date: 2024-06-04 10:08:02
 * @LastEditors: zhengqj
 * @LastEditTime: 2024-06-25 19:01:50
 * @Description: file content
 * @WebSite: http://webgis.fun
-->

<template>
  <el-config-provider :locale="location">
    <router-view />
  </el-config-provider>
</template>

<script setup>
// #region 外部引用库 begin
import { ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import ja from 'element-plus/es/locale/lang/ja'
import en from 'element-plus/es/locale/lang/en'
import ko from 'element-plus/es/locale/lang/ko'
const location = ref(zhCn)
const i18n = useI18n()

// 解决el-table ResizeObserver loop completed with undelivered notifications错误
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200)
    super(callback)
  }
}

onBeforeMount(() => {
  switch (i18n.locale.value) {
    case 'zh-CN':
      location.value = zhCn
      break
    case 'ja':
      location.value = ja
      break
    case 'ko':
      location.value = ko
      break
    case 'en':
      location.value = en
      break
    default:
      location.value = zhCn
  }
})
</script>

<style scoped lang="less">
</style>

