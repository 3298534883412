import { createI18n } from 'vue-i18n'
import zhCN from '@/local/zh-CN.js'
import en from '@/local/en.js'

import el_En from 'element-plus/es/locale/lang/en'
import el_zhCN from 'element-plus/es/locale/lang/zh-cn'

// 获取浏览器语言
export function getBrowserLanguage() {
  var browserLanguage = navigator.language
  if (browserLanguage.indexOf('zh') !== -1) {
    return 'zh-CN'
  } else if (browserLanguage.indexOf('en') !== -1) {
    return 'en'
  } else {
    return 'zh-CN'
  }
}

export const i18n = createI18n({
  locale: getBrowserLanguage(), // 设置语言类型
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages: {
    'zh-CN': {
      el: el_zhCN,
      ...zhCN
    },
    'en': {
      el: el_En,
      ...en
    }
  }
})
