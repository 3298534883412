import { add } from 'lodash'
import request from '../axios.js'

export default {
  async getModelDetail(id) {
    const res = await request({
      url: `/project-model/findByProjectId/${id}`,
      method: 'GET'
    })
    return res
  },
  async findPage(data) {
    const res = await request({
      url: `/project/findPage`,
      method: 'POST',
      data
    })
    return res
  },
  async getProjectById(id) {
    const res = await request({
      url: `/project/${id}`,
      method: 'GET'
    })
    return res
  },
  async delProjectById(id) {
    const res = await request({
      url: `/project/${id}`,
      method: 'DELETE'
    })
    return res
  },
  async addPorject(data) {
    const res = await request({
      url: `/project`,
      method: 'POST',
      data
    })
    return res
  },
  async updatePorject(data) {
    const res = await request({
      url: `/project`,
      method: 'PUT',
      data
    })
    return res
  },
  // 获取团队下类型信息
  async findTeamById(teamId) {
    const res = await request({
      url: `/project-type/findTeam/${teamId}`,
      method: 'GET'
    })
    return res
  },
  // 获取团队下所有成员
  async getTeamUserList() {
    const res = await request({
      url: `/team-client/users`,
      method: 'GET'
    })
    return res === null ? [] : res
  },
  // 项目下成员信息
  async getProjectUser() {
    const res = await request({
      url: `/project-belong/findProjectByUserId`,
      method: 'GET'
    })
    return res === null ? {} : res
  },
  // 修改项目成员
  async postPorjectUserIds(data) {
    return await request({
      url: `/project-belong/addOrUpdateList`,
      method: 'POST',
      data
    })
  },
  // 获取项目下的用户ID
  async getUserIdsByProjectId(projectId) {
    const res = await request({
      url: `/project-belong/findProjectByProjectId/${projectId}`,
      method: 'GET'
    })
    return res
  },
  // 上传模型前的校验
  async uploadModelValidate(data) {
    return await request({
      url: '/project-model/initModel',
      method: 'POST',
      data
    })
  },
  // 模型上传完成
  async uploadModelComplete(data) {
    return await request({
      url: '/project-model/uploadModel',
      method: 'POST',
      data
    })
  },
  // 查询项目模型
  async findProjectModel(projectId) {
    return await request({
      url: `/project-model/findByProjectId/${projectId}`,
      method: 'GET'
    })
  },
  // 所有团队
  async getAllTeams() {
    const res = await request({
      url: `/team-client/teams`,
      method: 'GET'
    })
    return res === null ? [] : res
  },
  // 所有团队
  async getTeamOwener(roleCode) {
    const res = await request({
      url: `/user-client/findTeamRoleUser`,
      method: 'GET',
      params: { roleCode }
    })
    return res
  },
  // 删除minio模型
  deleteModel(data) {
    return request({
      url: '/project-model/deleteModel',
      method: 'POST',
      data
    })
  },
  delProjectTypeById(id) {
    return request({
      url: `/project-type/${id}`,
      method: 'DELETE'
    })
  },
  createProjectType(data) {
    return request({
      url: '/project-type',
      method: 'POST',
      data
    })
  },
  // 查询项目下的自定义参数
  findAllCustom(data) {
    return request({
      url: '/project-custom/findAllCustom',
      method: 'POST',
      data
    })
  },
  // 新增自定义字段
  updateProjectCustomData(data) {
    if (data[0].id) {
      return request({
        url: '/project-custom',
        method: 'PUT',
        data: data[0]
      })
    } else {
      return request({
        url: '/project-custom/adds',
        method: 'POST',
        data
      })
    }
  },
  // 删除自定义字段
  deleteProjectCustomData(data) {
    return request({
      url: '/project-custom/delete',
      method: 'DELETE',
      data
    })
  },
  // 查询所有项目的自定义参数
  findProjectCustomList(data) {
    return request({
      url: '/project-custom/findProjectCustomList',
      method: 'POST',
      data
    })
  },
  // 查询团队各项目下病害类型字段
  findProjectDiseaseTypeList(data) {
    return request({
      url: '/disease-type/findProjectDiseaseTypeList',
      method: 'POST',
      data
    })
  },
  // 病害类型列表
  diseaseTypePage(params) {
    return request({
      url: '/disease-type/page',
      method: 'GET',
      params
    })
  },
  // 新增病害类型
  addDiseaseType(data) {
    return request({
      url: '/disease-type',
      method: 'POST',
      data
    })
  },
  // 修改病害类型
  updateDiseaseType(data) {
    return request({
      url: '/disease-type',
      method: 'PUT',
      data
    })
  },
  deleteDiseaseType(id) {
    return request({
      url: `/disease-type/${id}`,
      method: 'DELETE'
    })
  },
  addDiseaseTypeArr(data) {
    return request({
      url: '/disease-type/adds',
      method: 'POST',
      data
    })
  },
  updateModelParameter(data) {
    return request({
      url: '/project-model/update/model/parameter',
      method: 'POST',
      data
    })
  },
  getUserSocketStatus(){
    return request({
      url:'/project-model/find/project/model/user',
      method:'GET'
    })
  },
  getUpdateModelData(projectId){
    return request({
      url:`/project-model/find/model/init/parameter/${projectId}`,
      method:'GET'
    })
  }

}


