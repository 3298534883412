export default {
  goBack: 'Back',
  minimization: 'Minimize',
  pointHeader: 'Points library',
  add: 'New',
  import: 'Import',
  photoCounts: 'Images',
  chooseOneData: 'Select one or more list of inspection data.',
  editPoint: 'Edit inspection point',
  inspectionPointLocation: 'Inspection point location',
  pointSelected: 'Uploaded',
  Uploaded: 'Uploaded completed',
  reSelect: 'Reselect',
  imageManage: 'Image management',
  importCameraType: 'Import camera model',
  uploadFailList: 'Upload failure list',
  removeImageTips: 'Remove this image from the inspection point?',
  moveToOtherPoint: 'Move to another inspection point',
  drawPointTips: 'Use the [left mouse] to mark',
  deleteInspection: 'Delete',
  deleteInspectionTips: `{name}'s project will be permanently deleted, please enter the password to confirm. `,
  inspectionPhoto: 'Inspection image',
  inspectionPoint: 'Inspection point',
  createInspection: 'New inspection',
  searchProjectName: 'Search project name',
  startScreening: 'Start screening',
  projectNoInspection: 'No inspection is created for this project',
  editInspection: 'Edit',
  viewReport: 'View Report',
  pointManage: 'Inspection point management',
  inspectionMsg: 'Inspection information',
  inspectionImage: 'Inspection image',
  inspectionName: 'Inspection name',
  inspectionNamePlaceholder: 'Enter the inspection name',
  inspectionDescription: 'Inspection description',
  inspectionDescriptionPlaceholder: 'Enter the inspection description',
  InspectionNoPoint: 'No inspection points created',
  createPoint: 'New inspection point',
  inspectionList: 'Inspection list',
  computeImages: 'Binding to images',
  computeImagesTips: 'Move all images to the nearest inspection point.',
  docPreView: 'Report Preview',
  reportDownload: 'Download {name} Report',
  addPoint: 'New inspection point',
  deleteImageTips: 'Sure to delete this image?',
  pointNoImages: 'No images to this point',
  insDocList: 'Inspection report list',
  docName: 'Name',
  docStatus: 'Status',
  docUser: 'Responsible person',
  docCreateTime: 'Create time',
  docDelete: 'Delete report',
  docNewest: 'Latest report',
  docHistory: 'History report',
  docFail: 'Create failure',
  docCreating: 'Creating report',
  docStatusTips: 'Latest report: The latest inspection status report.<br /> History report: The report created in the past or has new inspection data been updated.',
  docDeleteTips: 'Sure to delete this inspection report?',
  inspectionReport: 'inspection report',
  diseaseSearchPlaceholder: 'Search for disease number',
  diseaseType: 'Defect type',
  diseaseTypePlaceholder: 'Please select the disease type',
  diseaseScalePlaceholder: 'Please select the disease scale',
  diseaseExport: 'Export defect',
  diseaseSize: 'Size',
  diseaseEdit: 'Editing defect',
  marker: 'Marker',
  updater: 'Updater',
  diseaseDeleteTips: 'Sure to delete all information about the defect?',
  diseaseList: 'List of diseases',
  diseaseDescPlaceholder: 'Please enter a defect description',
  time: 'time',
  length: 'length',
  width: 'width',
  area: 'area',
  noImagesTips: 'No images to bind this defect',
  disease: 'defect',
  total: 'total',
  tiao: 'diseases',
  allDisease: 'All defect',
  hideList: 'Hide list',
  showProjectList: 'Show list',
  hideProjectList: 'Hide list',
  addImageItem: 'New data',
  imageUploadTips: 'Upload tips: Photos with different camera models or shooting distances must be uploaded in groups, and the size of a single image does not exceed 100MB',
  cameraType: 'Camera type',
  cameraTypePlaceholder: 'Please select a camera type',
  photoDistance: 'Capture distance (m)',
  photoDistancePlaceholder: 'Please enter the capture distance',
  photoDistancePattern: 'Please enter an integer greater than 0',
  sheetNum: 'images',
  inspectionDelTips: 'Sure to delete this list of inspection image information? ',
  imageUploadPlaceholder: ' Select images to upload ',
  createDoc: 'Create report',
  pixelVerify: ' The resolution of the currently selected [{model}] camera ({resolvingPowerLong}*{resolvingPowerWide}) and the resolution of the uploaded image ({PixelXDimension}*{PixelYDimension}) Inconsistent, continue uploading will cause defect size and position anomalies, do you want to continue? ',
  noDisease: 'No defect of this inspection.',
  editSubmitEmpty: 'Add inspection images',
  selectRepeatDir: 'The current selected folder [{name}] is the same as the last uploaded folder. Do you want to continue?',
  noReport: 'No report',
  chooseImageTips: 'Select image folder',
  AIScreening: 'AI Screening',
  reportDiseaseTitle: 'Report disease table custom configuration',
  selectTableFieldSort: 'Select the table fields and sort them',
  tablePreview: 'Table preview effect',
  reportDiseaseOutLimit: 'Select a maximum of eight fields in the report disease form',
  exportDiseaseRange: 'disease derived range',
  exportDoubtfuldata: 'Export doubtful data',
  exportTobeReviewedData: 'Export the data to be reviewed',
  AIDisable: 'AI is not available',
  startGenerate: 'Start generation',
  allPhoto: 'All photos ',
  notScreeningPhoto: 'Unscreened photo ',
  impeachPhoto: 'Questionable Photo',
  reportCreateSuccessTitle: 'inspection report generation success',
  reportCreateSuccessText: 'Inspection report generated successfully, please check the latest report ',
  reportCreateFailTitle: 'Inspection report failed to be generated',
  reportCreateFailText: 'Failed to generate the inspection report. Please re-generate the report',
  currentInspectionDelete: 'The current inspection is deleted',
  diseaseBatchDeleteConfirmText: 'Are you sure to delete the selected disease data?',
  uploadImageEmpty: 'No inspection images have been uploaded. Please',
  docConfigTitle: 'Generate report custom configuration',
  filterDiseaseTasks: 'Filter disease scope',
  openFilter: 'Expand',
  foldFilter: 'Fold',
  partName: 'Component',
  partNamePlaceholder: 'Total number of components',
  partNameCheckedPlaceholder: ', selected',
  pickPart: 'Select component',
  unit: 'piece',
  all: 'All',
  batchEdit: 'Batch modification',
  sortTips: 'Drag to sort',
  totalSummary: 'Total',
  unBindPart: 'Unbind component',
  diseaseNumSet: 'Set the number of displayed diseases',
  diseaseNumPlaceholder: 'Please set the number of displayed diseases for each component, which can be set by the top value of the disease area, the width or the length',
  selfDefined: 'Custom disease type',
  filterResult: 'Disease filter result',
  filterResultSum: 'Total number of diseases',
  favDisease: 'Favorite disease',
  redoTips: 'Undo changes',
  doSearchTips: 'Click query to reset the last query result',
  faved: 'collect',
  notFaved: 'Not collected',
  removeTip: 'Remove',
  favPanelTitle: 'Add to filter result',
  allSelect: 'Select all',
  noFavDisease: 'Favorite folder is empty',
  addImageGroup: 'add groups',
  editImageGroup: 'edit groups',
  groupName: 'group name',
  groupNameExists: 'The group name already exists',
  groupNameLengthError: 'The group name cannot exceed 10 characters',
  selectImageFold: 'Select folder',
  addImageFold: 'Add folder',
  selectImageFoldTips: 'Please select a folder containing inspection images',
  uploadStatus: 'Upload status',
  autoComputed: 'Auto computed',
  placeAddImage: 'Select the inspection image',
  imageUploadBreakTips: 'Not all upload completed, please select the original folder to continue ',
  imageUploadBreakReselectTips: 'file selected, click on the submit to continuingly',
  imageUploadDirErrorTips: 'The currently selected folder [{name}] is different from the last uploaded folder, do you want to continue? ',
  zoomRatio: 'Zoom ratio',
  noModel: 'No model',
  computedValue: 'Computed value',
  computeDistanceLoadingText: 'In distance calculation, click [here] to cancel calculation',
  groupDeleteError: 'Please delete the images in the group first',
  'no-bind-marking': 'No component part',
  click2Fav: 'Click to collect',
  favStatues: 'Collection status',
  docReReport: 'Re-generate',
  imgStatus: 'Image status',
  imgGroups: 'Image group',
  imgName: 'Image name',
  imgNamePlaceholder: 'Please enter the image name',
  uploadTime: 'Upload time',
  batchDownload: 'Batch download',
  batchDelete: 'Batch delete',
  unscreened: 'unscreened',
  reviewed: 'Screened',
  doubt: 'Suspicious',
  batchDeleteTips: 'Are you sure to delete the selected inspection images?',
  downImg: 'Download image',
  downDiseaseImg: 'Download disease image',
  diseaseNumLimit: 'Disease number exceeds 5000',
  choose: 'Select',
  sort: 'Sort',
  hasDisease: 'Has disease',
  hasNotDisease: 'No disease',
  diseaseSizeTips: 'Set the number of displayed diseases by the top value of the disease area, the width or the length',
  unitTips: 'S=Area,L=Length,W=Width',
  L: 'Length',
  W: 'Width',
  S: 'Area',
  diseaseImgView: 'View details',
  serviceError: 'Service exception',
  filterImg: 'Filter Image',
  collectionSuccess: 'Collection success ',
  collectionCancel: 'Unbookmark successful',
  batchData: 'Batch delete',
  batchDataTips: 'Are you sure to delete the selected inspection images? Please enter the login password to verify, confirm that the data will be permanently deleted.',
  dataLoading: 'Data loading',
  noMoreData: 'No more data'
}
