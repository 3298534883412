export default {
  accountLogin: 'Login',
  wxLogin: 'WeChat Login',
  phoneNumber: 'Phone number',
  phoneNumberPlaceholder: 'Please enter your phone number.',
  phoneNumberFormatError: 'Please enter a valid phone number.',
  password: 'Password',
  passwordPlaceholder: 'Please enter your password',
  forgetPassword: 'Forget password',
  clickButtonStartVerify: 'Click the button to start verification',
  verifySuccess: 'Successful verification',
  placeVerify: 'Please follow the instructions to complete the verification',
  login: 'Login',
  logging: 'Logging in',
  unregistered: 'Unregistered',
  toRegister: 'Go to Register',
  verifyBoxTitle: 'Please complete the security verification',
  loginSuccess: 'Login successful',
  findPassword: 'Recover password',
  SMSVerificationCode: 'SMS verification code',
  SMSVerificationCodePlaceholder: 'Please enter the SMS verification code',
  getVerifyCode: 'Get verification code',
  resend: 'resend',
  resetPassword: 'Reset password',
  setNewPassword: 'Set a new password',
  repassword: 'Re-enter password',
  repasswordPlaceholder: 'Please enter your password again',
  passwordInconsistency: 'The two passwords do not match.',
  passwordFormatTips: 'The password must include letters and numbers and be 8-20 characters long.',
  resetPasswordSuccess: 'Successfully reset the password',
  haveAccount: 'Existing account',
  toLogin: 'Go and log in',
  setPassword: 'Set a password',
  completeInfomation: 'Improve registration information',
  name: 'Name',
  namePlaceholder: 'Please enter your name',
  companyName: 'Name of your organization/school',
  companyNamePlaceholder: 'Please enter the name of your organization or school.',
  job: 'Your position (occupation)',
  jobPlaceholder: 'Please select your position.',
  agree: 'I have read and agree to the terms.',
  licenseAgreement: 'License agreement',
  privacyAgreement: 'Privacy agreement',
  register: 'Register',
  checkUserAgreement: 'Please check the User Agreement first',
  registerSuccess: 'Registered successfully',
  sliderVerifyTips: 'Swipe right to complete verification',
  verifyFail: 'Validation failed',
  'repeatRegister': 'This phone number has been registered, please login directly',
  loginTitle: 'Login Inspect Cloud',
  registerTitle: 'Register Account',
  broswerTipsText: ' There are compatibility issues with the current browser and some functions of the system may not work properly. You are advised to use the following browsers. ',
  googleChrome:'Google Chrome',
  edge:'Edge'
}
