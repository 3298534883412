
import request from '../axios.js'

export default {
  async getLinkAnalysis(sid) {
    return await request({
      method: 'GET',
      url: `/team-client/link-analysis?sid=${sid}`
    })
  },
  async postJoinTeam(params) {
    return await request({
      method: 'POST',
      url: `/team-client/join`,
      params
    })
  }

}
