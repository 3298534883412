export default {
  notice: 'Notice',
  maintenance: 'Maintenance Notice',
  renewal: 'Renewal Notice',
  newFeature: 'New Feature',
  clearAll: 'Clear',
  markRead: 'Mark all as read',
  noNotice: 'No notifications',
  systemNotic: 'System notification'
}
