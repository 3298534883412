import request from '../axios.js'

export default {
  // 获取、查询所有列表数据
  getAllData: async(params) => {
    const res = await request({
      url: `/transaction`,
      params,
      method: 'GET'
    })
    return res
  },
  // 批量还原
  restoreAll: async(ids) => {
    const res = await request({
      url: `/transaction/data/restore`,
      data: { ids },
      method: 'POST'
    })
    return res
  },
  // 单个还原
  restore: async(id) => {
    const res = await request({
      url: `/transaction/data/restore`,
      data: { ids: [id] },
      method: 'POST'
    })
    return res
  },

  // 批量彻底删除
  completelyDeleteAll: async(ids) => {
    const res = await request({
      url: `/transaction/data/delete`,
      data: { ids },
      method: 'POST'
    })
    return res
  },
  // 单个彻底删除
  completelyDelete: async(id) => {
    const res = await request({
      url: `/transaction/data/delete`,
      data: { ids: [id] },
      method: 'POST'
    })
    return res
  },
  // 清空回收站
  cleanRecycleBin: async() => {
    const res = await request({
      url: `/transaction/clean`,
      method: 'POST'
    })
    return res
  },
  getStorage: async(params) => {
    const res = await request({
      url: `/transaction/total/size`,
      method: 'GET'
    })
    return res
  }
}
