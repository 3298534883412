/*
 * @Author: zhengqj
 * @Date: 2024-06-21 10:41:53
 * @LastEditors: zhengqj
 * @LastEditTime: 2024-06-21 11:01:55
 * @Description: API 请求 国际化-EN

 */
export default {
  // http 请求
  cookie: 'The login has expired. Please log in again',
  serviceError: 'Server error. Please contact the administrator.',
  offLine: 'Network error. Please check your connection.',
  timeout: 'Request timed out. Please try again',
  notFound: 'The requested address does not exist. Please contact the administrator',

  USER_PHONE_EXISTING: 'User phone existing',
  INSPECTION_IN_AI_CAN_NOT_OPERA: 'AI is screening, please operate later',

  // 系统 2000,2003,5000,4003
  // 项目相关
  PROJECT_ERROR: 'Error creating project',
  PROJECT_NO_ERROR: 'Project does not exist',
  PROJECT_TYPE_CODE_ERROR: 'Project type is duplicated',
  PROJECT_MODEL_USER: 'The model is already being uploaded by another user',
  PROJECT_NAME_EXITS_ERROR: 'Project name already exists',
  INSPECTION_NO_ERROR: 'Inspection record does not exist',
  INSPECTION_DISEASE_NOT_EXISTING: 'Inspection defect does not exist',
  INSPECTION_NODE_NOT_EXISTING: 'Inspection point does not exist',
  DISEASE_TYPE_UPDATE_ERROR: 'The built-in disease type cannot be modified',
  PROJECT_MODEL_VERSION_ERROR:' Upload failed, please refresh the page and try again ',
  PROJECT_MODEL_MD_ERROR:' Upload failed, please refresh the page and try again ',
  PROJECT_MODEL_UPLOAD_ERROR:' Upload failed, please refresh the page and try again ',

  // 巡检管理
  REPORT_ERROR: 'Error generating report.',
  TEMPLATE_FILE_ERROR: 'Eerror template file',
  INSPECTION_IMAGE_NOT_EXISTING: 'Inspection photo does not exist',
  REPORT_UPDATE_ERROR: 'The report is being generated. Please try again later',
  IMAGES_GROUP_NAME_ERROR: 'The picture group name is the same',


  // 巡检点
  INSPECTION_NODE_REPEAT: 'A inspection point with same latitude, longitude, and height already exists.',
  // 病害管理
  EXCEL_ERROR: 'Error generating Excel file.',
  NO_DISEASE_ERROR: 'Defect does not exist',
  INSPECTION_DISEASE_TYPE_NOT_EXISTING: 'Inspection defect type does not exist',
  DISEASE_EXPORT_ERROR: 'Description The number of diseases exceeded the upper limit',
  PROJECT_CUSTOM_NAME_ERROR: 'Disease name duplication',
  CUSTOM_TYPE_ERROR: 'It is bound to a disease and cannot be modified',
  CUSTOM_INIT_ERROR: 'The option cannot be modified because it is bound to a disease',
  DISEASE_TYPE_BING_DISEASE: 'Disease type has been used, deletion failed ',
  BIND_DISEASE_NO_UPDATE: 'Disease type has been used, cannot switch display state ',
  // 团队管理
  USER_NO_TEAM_ERROR: 'The user is not associated with this team.',
  TEAM_USER_IN_TEAM: 'You are already a member of this team. Please do not join again.',
  TEAM_USER_COUNT_UPPER_LIMIT: 'The team is full. Please contact your administrator',
  TEAM_INVITATION_CODE_INVALID: 'The link verification code is incorrect. You have {checkCount} attempts remaining.',
  TEAM_INVITATION_LINK_INVALID: 'The link does not exist or has expired',
  TEAM_USER_JOIN_COUNT_LIMIT: 'The user has entered the team password incorrectly more than 5 times. Please try again after 1 hour.',
  TEAM_OWNER_CAN_NOT_REMOVE: 'Team owner cannot be deleted',
  TEAM_OPERA_INCONSISTENT: `The team of the object being operated on by the user does not match the user's logged-in team`,
  TEAM_VOLUME_EXCEED: 'Team storage capacity is up, please upgrade',
  // 通知
  NOTICE_SEND_FAIL: 'Message delivery failed.',
  JSON_ERROR: 'SSE message push failed',

  // 鉴权管理
  // code 4001
  NO_AUTH: 'No permission to perform this operation.',
  ROLE_CHANGE_LIMIT: 'Parameters error',
  // 系统管理
  INVALID_CODE_ERROR: 'Incorrect verification code.',
  TEAM_RIEBO_NOT_EXISTING: 'RIEBO team does not exist',
  // 登录、登出
  USER_SMS_CODE_CHECK_FAIL: 'Failed to verify the verification code',
  USER_PHONE_NOT_EXISTING: 'The phone number does not exist',
  USER_OR_PASSWORD_ERROR: 'Incorrect username or password',
  USER_REGISTER_FAIL: 'Registration failed.',
  USER_PASSWORD_CHECK: 'Incorrect verification password',
  LOGIN_FAILURE: 'Login has expired.',
  SEND_SMS_FAIL_HOUR: 'The number of short messages sent exceeds the upper limit. Please try again later',
  SEND_SMS_FAIL_ERROR: 'Failed to send the message',
  MOBILE_NUMBER_ILLEGAL: 'Incorrect phone number format',
  AREA_ERROR: 'Region error',
  LOGIN_CLIENT_ERROR: 'User login client is not valid',
  LOGIN_REPEATED: 'This account is already logged in.',
  SEND_SMS_FAIL_MIN: 'Message sending limit reached. Please try again in one minute.',
  SEND_SMS_FAIL_DAY: 'Message sending limit reached.  Please try again after one day.',
  ABNORMAL_OPERATION: 'Illegal operation',
  USER_ACCOUNT_ABNORMALITY: 'User has disabled ',
  // 文件操作
  FILE_NO_CONTENT: 'Unable to resolve file content.',
  FILE_SAVE_FAIL: 'File save failed.',
  FILE_PARSE_FAIL: 'File parsing failed',

  // 空间点
  PARENT_TREE_PARAMETER: 'Space point parameter error',
  PARENT_TREE_ID_NOT_ERROR: 'Space point parameter error',
  PARAMETER_ERROR: 'Parameter error',
  PARENT_BIND_NODE: 'Parent space point cannot be added as a child space point.',
  PARAMETER_PROBLEM: 'Parameter error',
  // AI
  START_AI_TYPE_ERROR: 'Start AI type error',
  CURRENT_INSPECTION_NO_IMAGE: 'No image was found in the current inspection.',
  AI_SERVER_EXCEPTION: 'AI server exception',
  START_AI_CHECK_FAIL: 'Start AI check failed',
  AI_CHECK_QUEUE_POSITION_FAIL: 'The AI checks for abnormal queue positions',
  AI_CHECK_QUEUE_JUMP_FAIL: 'AI screens for queue-jumping abnormalities',
  AI_CHECK_CANCEL_FAIL: 'Failed to cancel AI screening',
  CURRENT_INSPECTION_NO_AI_IMAGE: 'At present, there are no photos screened by AI',
  NO_INSPECTION_FOR_THE_IMAGE: "Inspection information to which the image belongs is not queried.",
  PROJECT_MODEL_ERROR_FOR_THE_IMAGE:" The project model of the image cannot be queried ",
  IMAGE_ERROR_FOR_THE_DISEASE:" No disease image information found or incomplete information ",
  IMAGE_PX_LENGTH_CALCULATION_ERROR:" Image pixel length calculation error ",
  DISEASE_TYPE_NOT_COVER_AI:" Disease type does not cover AI screening result type ",
  DISEASE_IMAGE_URL_NULL:" disease picture URL is empty ",
  AI_RESULT_SAVE_ERROR:"AI result saving exception ",
  TOW_D_TO_3D_FAIL:" Abnormal transition from 2D to 3D coordinates ",
  AI_SERVER_CHECK_ERROR:"AI service verification exception ",
  CURRENT_INSPECTION_NO_AI_DISEASE_TYPE:" No AI disease type currently inspected ",
  AI_SERVER_RECOGNITION_TIME_OUT: "AI service identification timeout.",
  AI_SERVER_DOWN_IMG_URL_NOT_EXIST:"AI service download image url does not exist ",
  AI_SERVER_IMG_DECRYPT_FAIL:"AI service image decryption failure ",
  FEEDBACK_AI_RESULT_PARAM_EMPTY:" The required parameters for AI screening result feedback are empty ",
  FEEDBACK_AI_RESULT_NOT_EXISTING:"AI screening information does not exist, the associated data may have been deleted ",
  GROUP_DOWN_IMAGE_ERROR:" There are still images in the deleted image group ",
  IMAGES_GROUP_NAME_ERROR:" Image group name duplicate",
  IMAGES_DRAW_ERROR:' Picture drawing failed ',
  TASK_CANCELING: 'Task being canceled ',

}
