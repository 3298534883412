import common from './base/Common_zh_CN'
import api from '@/api/lang/Api_zh_CN'
import project from '@/views/Project/lang/Project_zh-CN'
import imageEditor from '@/components/imageEditor/lang/imageEditor_zh-CN'
import threeApi from '@/components/threeApi/lang/threeApi_zh-CN'
import Inspection from '@/views/Inspection/lang/Inspection_zh-CN'
import file from './base/file_zh_CN'
import login from '@/views/Login/lang/login_zh-CN'
import licence from '@/views/Licence/lang/Licence_zh_CN'
import inviteJoin from '@/views/InviteJoin/lang/InviteJoin_zh_CN'
import InspectionDetail from '@/views/InspectionDetail/lang/InspectionDetail_zh-CN'
import noticeTool from '@/views/Notice/lang/NoticeTool_zh_CN'
import sysTools from '@/views/SysTools/lang/SysTools_zh_CN'
import personalCenter from '@/views/PersonalCenter/lang/PersonalCenter_zh_CN'
import team from '@/views/Team/lang/team_zh_CN'
import legend from '@/components/Legend/lang/legend_zh-CN'
import dict from './base/dict_zh-CN'
import spatialMarker from '@/views/SpatialMarker/lang/spatialMarker_zh_CN'
import compareImageEditor from '@/components/CompareImageEditor/lang/compareImageEditor_zh-CN'
import AI from '@/views/AI/lang/index_zh-CN'
import recycleBin from '@/views/RecycleBin/lang/RecycleBin_zh_CN.js'
export default {
  common,
  api,
  project,
  imageEditor,
  threeApi,
  Inspection,
  file,
  login,
  licence,
  inviteJoin,
  InspectionDetail,
  legend,
  noticeTool,
  sysTools,
  personalCenter,
  team,
  dict,
  spatialMarker,
  compareImageEditor,
  AI,
  recycleBin
}
