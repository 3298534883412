export default {
  imageLoading: 'Loading...',
  twoPointsTips: 'Use [left click] to draw, [right click] to finish after at least [two points].',
  threePointsTips: 'Use [left click] to draw, [right click] to finish after at least [two points].',
  distance: 'Length: ',
  fourPointsTips: 'Please draw by mouse [left button], draw at least [three points] and then end by mouse [right button]',
  area: 'Area: ',
  totalDistance: 'Total Length: ',
  imageScale: 'Scale',
  imageFull: 'Increase scale by 100%',
  postProgress: 'Light effect processing',
  imageInfo: 'Image info.',
  imageDownload:'Image download',
  clearMeasure: 'Clear measurement',
  measureArea: 'Area measurement',
  measureLine: 'Linear measurement',
  measureRuler: 'Caliper measurement',
  drawRect: 'Draw rectangle (shortcut key: 1)',
  drawLine: 'Free drawing (shortcut key: 2)',
  exposure: 'Exposure',
  construct: 'Construct',
  sharpen: 'Sharpen',
  imageNo: 'Image ID',
  createTime: 'Create time',
  cameraType: 'Camera type',
  factory: 'Manufacturer',
  rulerTitle: 'Caliper (mm)',
  rulerError: 'Caliper tool initialization error, unable to load',
  downloadOriginalImage:' Download the original image ',
  downloadDiseaseImage:' Download disease diagram ',
  drawDisableTips:' Mark disease, need to switch to screening mode ',
  rectDelete:'Box select delete (shortcut key: 3)'
}
