export default {
  logo: 'Inspect Cloud',
  cancel: 'Cancel',
  confirm: 'Confirm',
  error: 'Error',
  fail: 'Fail',
  warn: 'Warning',
  success: 'Successed',
  delete: 'Delete',
  loading: 'Loading...',
  back: 'Back',
  next: 'Next',
  pre: 'Previous',
  action: 'More',
  edit: 'Edit',
  add: 'Add',
  import: 'Import',
  done: 'Done',
  submit: 'Submit',
  inputEmptyTips: 'Input value cannot be empty.',
  maxInputLabelsTips: 'Up to 50 characters.',
  placeholder: 'Please enter',
  save: 'Save',
  handleSuccess: 'Operation succeeded.',
  fullScreen: 'Full screen',
  remove: 'Remove',
  move: 'Move',
  passwordValid: 'Password verification',
  passwordPlaceholder: 'Enter your password',
  passwordError: 'Wrong password',
  deleteSuccess: 'Successfully deleted.',
  selected: 'Select',
  currentUser: 'Current user',
  backToHome: 'Home',
  addSuccess: 'Successfully added.',
  dataEmpty: 'No data available.',
  pageNotFound: 'Page not found.',
  measure: 'Measurement',
  measureTri: 'Distance measurement',
  reset: 'Reset',
  activation: 'Software activation',
  know: 'OK',
  hint: 'Tips',
  systemHint: 'System tips',
  confirmDelete: 'Confirm delete',
  close: 'Close',
  piece: 'Images',
  me: 'Me',
  noData: 'No data',
  image: 'Images',
  check: 'Inspect',
  allClear: 'All clear',
  hour: 'h',
  day: 'd',
  minutes: 'minutes',
  imageChecked: 'Image checked',
  noModelTips: 'The project does not has model file!',
  imagePoint: 'Image point',
  inspectionPoint: 'Inspection point',
  inspection: 'Inspection',
  personalCenter: 'Personal center',
  bindSuccess: 'Binding successful',
  noBindData: 'No image matches the inspection point.',
  removeTeam: 'You have been removed from the {team} team',
  templeSave: 'Draft',
  logoutTips: 'Logout success',
  confirmLogin: 'Confirm login',
  confirmLoginTips: 'This account is already logged in, the other user will be offline, sure to continue?',
  cancelLogin: 'Cancel login',
  otherLogin: 'This account is already logged in!',
  otherLoginSuccess: 'You have been signed out by another user!',
  saving: 'Saving...',
  prePage: 'Previous',
  nextPage: 'Next',
  feedbackNotEmpty: 'The feedback content cannot be empty',
  check_1: 'Inspected',
  check_0: 'To be done',
  check_2: 'Impeach',
  check_1_tips: ' Screened, click to switch to unscreened ',
  check_0_tips: ' Not screened, click to switch to screened ',
  check_2_tips: ' doubtful ',
  index: 'NO',
  fieldName: 'Field name',
  dragsort: 'Drag sort',
  systemNotic: 'System notification',
  known: 'Known',
  lastRefreshTime: 'Last refresh time',
  ImpeachPhoto: 'Impeach photo',
  yes: 'Yes',
  no: 'No',
  creator: 'creator',
  system: 'System',
  uploadNetError: 'Network error, upload failed',
  uploadServerError: 'The network is abnormal. Please refresh the page and try again',
  sort: 'Sort',
  input: 'Input',
  select: 'Select',
  multiplySelect: 'Multiple select',
  option: 'Option',
  default: 'Default',
  placeholderSelect: 'Please select',
  size: 'Size',
  number: 'Number',
  show: 'Show',
  backHome3D: 'Back to default view',
  backHome: 'Home',
  batchDelete: 'Batch deletion',
  reDraw: 'Redraw',
  search: 'Search',
  isUploading: 'Uploading',
  imageDownload: 'Image download',
  cancelDownload: 'cancel',
  downloadComplete: 'Download complete',
  uploadSuccess: 'Upload success',
  serviceError: 'Service error',
  deleteLoading: 'Next(D)',
  beforePageWithA: 'Previous(A)',
  refresh: 'Refresh',
  modification: 'edit',
  unitComponent: 'component',
  state: 'State',
  none: 'none'
}
