import request, { BASE_URL } from '../axios.js'

export default {
  changeImageCheck: async(data) => {
    const res = await request({
      url: `/inspection-image`,
      method: 'PUT',
      data
    })
    return true
  },
  getDiseaseDetail: async(id) => {
    const res = await request({
      url: `/inspection-disease/${id}`,
      method: 'GET'
    })
    return res || {}
  },
  getDiseaseTypes: async(params) => {
    const res = await request({
      url: `/disease-type/list`,
      method: 'GET',
      params
    })
    return res || []
  },
  addDisease: async(data) => {
    const res = await request({
      url: `/inspection-disease`,
      method: 'POST',
      data
    })
    return true
  },
  editDisease: async(data) => {
    const res = await request({
      url: `/inspection-disease`,
      method: 'PUT',
      data
    })
    return true
  },
  getDiseaseByImage: async(data) => {
    const res = await request({
      url: `/inspection-disease/list/img`,
      method: 'POST',
      data
    })
    return res || []
  },
  deleteDisease: async(id) => {
    const res = await request({
      url: `/inspection-disease/${id}`,
      method: 'DELETE'
    })
    return true
  },
  deleteDiseaseByIds(data) {
    return request({
      url: '/inspection-disease/deleteIds',
      method: 'DELETE',
      data
    })
  }
}
