export default {
  all: '全部',
  enterProject: '进入项目',
  createProject: '新建项目',
  projectInfo: '项目信息',
  projectInfoDes: '设置项目基本信息',
  uploadModel: '上传模型',
  uploadModelDes: '上传项目3D模型',
  createTime: '创建时间',
  projectName: '项目名称',
  projectNameDes: '请输入项目名称，最多50字符',
  projectType: '项目类型',
  auth: '授权其他成员访问',
  projectUser: '项目成员',
  projectIcon: '项目封面',
  projectDetail: '项目简介',
  projectDetailDes: '请输入项目简介',
  choosed: '已选择[0]人',
  chooseMember: '选择项目成员',
  teamMember: '全部成员',
  projectMember: '按项目成员选择',
  selectedUser: '已选成员：[0]人',
  clearAll: '全部清除',
  selecteAll: '全选',
  removeUser: '移除成员',
  searchUser: '用户查询',
  addSuccess: '创建项目成功',
  teamUserError: '项目团队用户信息获取异常',
  deleteSuccess: '项目删除成功',
  backAlertTitle: '确认返回主页',
  backHome: '返回主页',
  editing: '你正在编辑项目，是否要返回主页？',
  chooseFile: '选择文件',
  rechooseFile: '重新上传模型',
  startUpload: '开始上传',
  continueUpload: '继续上传',
  modelUploadExmaple: '格式示例',
  modelUploadExampleTips: '选择带json文件的根目录文件夹',
  cancelUpload: '取消上传',
  pauseUpload: '暂停上传',
  uploadSuccess: '更新成功',
  uploadErrorTips: '上传失败，请选择原文件路径后点击继续上传',
  noTilesetJSON: '选择的模型中没有tileset.json文件',
  home: '主页',
  teamMangement: '团队管理',
  searchTeam: '搜索团队',
  saveModelCatchLoading: '模型文件解析中,请稍等...',
  noProject: '暂无数据',
  uploadImg: '上传封面',
  reUpload: '重新上传',
  uploadFailed: '封面上传失败',
  modelError: '模型异常/未上传',
  userUploading: '用户{name}正在上传中',
  projectNotExist: '项目不存在',
  selectNoEmpty: '请选择项目',
  selectedUserNoEmpty: '请选择项目成员',
  backAlertContent: '你正在编辑项目，是否要返回主页？',
  owner: '所有者',
  me: '我',
  deleteProject: '删除项目',
  deleteProjectTips: '你正在删除 {name} 项目，请输入登录密码验证，点击删除该项目将被移入回收站。',
  projectTypeName: '项目类型名称',
  projectTypeNamePlaceholder: '请输入项目类型名称',
  projectTypeIcon: '项目类型图标',
  createProjectType: '创建项目类型',
  projectTypeIconPlaceholder: '请选择项目类型图标',
  projectTypeList: '删除失败，请先删除当前项目类型下的项目，共计[0]个项目',
  projectConfig: '项目配置',
  diseaseFieldConfig: '病害字段配置',
  diseaseTypeFieldConfig: '病害类型字段配置',
  addField: '新增字段',
  importForOtherProject: '从其他项目导入',
  diseaseFieldName: '病害字段名称',
  diseaseFieldDeleteText: '确定删除该病害字段吗？',
  addDiseaseField: '新增病害字段',
  editDiseaseField: '编辑病害字段',
  diseaseFieldPlaceholder: '请输入字段名称',
  importDiseaseField: '导入病害字段',
  selectedDiseaseType: '已选择病害类型',
  selectedDiseaseField: '已选择病害字段',
  diseaseFieldDeleteConfirmText: '此病害字段已被使用，是否强制删除？',
  diseaseTypeName: '病害类型名称',
  diseaseMarkerColor: '病害标记颜色',
  diseaseVisible: '是否显示',
  diseaseVisibleTips: '在新增病害时，是否显示该病害类型。',
  diseaseTypeColorPlaceholder: '请选择病害标记颜色',
  diseaseTypeNamePlaceholder: '请输入病害类型名称',
  newType: '新增类型',
  diseaseTypeDeleteText: '确定删除该病害类型吗？',
  importDiseaseType: '导入病害类型',
  widgetType: '表单类型',
  diseaseFieldVisibleTips: '在标记病害时，是否显示该病害字段。',
  diseaseFormPreview: '预览效果',
  diseaseFieldPreviewTitle: '病害字段-预览',
  selectOptionError: '请添加下拉框选项',
  currentProjectDelete: '当前项目已被删除',
  uploadModelRepeat: '您正在上传模型，请勿重复操作',
  uploadModelSelectText: '您选择的模型文件与上次所选不一致，是否覆盖新上传新的模型？',
  residualSpace: '剩余空间',
  residualSpaceTitle: '剩余空间不足',
  totalCapacity: '总容量',
  usedCapacity: '已用容量',
  expansionCapacity: '立即扩容'

}
