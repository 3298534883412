
/*
 * @Author: zhengqj
 * @Date: 2024-06-14 11:45:25
 * @LastEditors: zhengqj
 * @LastEditTime: 2024-06-14 16:45:21
 * @Description: 路由权限操作
 */
export const ROUTER_KEY = 'router'
const TOKEN = 'rieboToken'
const PROJECT_TYPE = 'projectType'
const PROJECT_INFO = 'projectInfo'
const PROJECT_ID = 'projectId'
const TEAM_OWNER = 'teamOwner'
export class session_Storage {
  static get(key) {
    return sessionStorage.getItem(key)
  }
  static set(key, value) {
    if (value) {
      sessionStorage.setItem(key, value)
    }
  }
  static remove(key) {
    sessionStorage.removeItem(key)
  }
}
export class local_Storage {
  static get(key) {
    return localStorage.getItem(key)
  }
  static set(key, value) {
    if (value) {
      localStorage.setItem(key, value)
    }
  }
  static remove(key) {
    localStorage.removeItem(key)
  }
}

export const TokenStorage = {
  get() { return local_Storage.get(TOKEN) },
  set(token) { local_Storage.set(TOKEN, token) },
  remove() { local_Storage.remove(TOKEN) }
}
export const ProjectTypeStorage = {
  get() { return session_Storage.get(PROJECT_TYPE) },
  set(projectType) { session_Storage.set(PROJECT_TYPE, projectType) },
  remove() { session_Storage.remove(PROJECT_TYPE) }
}

export const ProjectInfoStorage = {
  get() { return session_Storage.get(PROJECT_INFO) },
  set(projectInfo) { session_Storage.set(PROJECT_INFO, projectInfo) },
  remove() { session_Storage.remove(PROJECT_INFO) }
}
export const ProjectIdStorage = {
  get() { return session_Storage.get(PROJECT_ID) },
  set(projectId) { session_Storage.set(PROJECT_ID, projectId) },
  remove() { session_Storage.remove(PROJECT_ID) }

}
export const OwnerStorage = {
  get() { return session_Storage.get(TEAM_OWNER) },
  set(owner) { session_Storage.set(TEAM_OWNER, owner) },
  remove() { session_Storage.remove(TEAM_OWNER) }
}
// (function() {
//   // 该事件是核心，增加window监听事件

//   window.addEventListener('storage', function(event) {
//     console.log('storage', event)
//     if (event.key === 'isActiving') {
//       if (event.newValue) {
//         sessionStorage.setItem('isActiving', event.newValue)
//       } else {
//         sessionStorage.removeItem('isActiving')
//       }
//     }
//   })
// })()
