import { defineStore } from 'pinia'
import { RouterMangerApi } from '@/api'
import { createDynamicRoutes } from '@/router'
export const routerAndPremission = defineStore('routerAndPremission', {
  state: () => {
    return {
      router: [],
      permissions: [],
      updateKey: new Date().getTime()
    }
  },
  actions: {
    refreshUpdateKey() {
      this.updateKey = new Date().getTime()
    },
    async resetRouterAndPermission(isLogin) {
      this.router = isLogin ? [
        {
          menuPath: '/license',
          pathName: 'License',
          menuName: '许可',
          componentPath: 'Licence/Licence.vue',
          requiresAuth: false
        }
      ] : [
        {
          menuPath: '/license',
          pathName: 'License',
          menuName: '许可',
          componentPath: 'Licence/Licence.vue',
          requiresAuth: false
        },

        {

          componentPath: 'PersonalCenter/PersonalCenterPage.vue',
          pathName: 'PersonalCenter',
          menuName: '个人中心',
          menuPath: '/personnelcenter',
          requiresAuth: true
        }]
      this.permissions = []
      createDynamicRoutes(this.router)
    },
    async getRouterAndPremission(roleId) {
      const data = await RouterMangerApi.getRouterTree(roleId)
      this.router =
        data.menuVOList.length > 0
          ? data.menuVOList
          : [
            {
              menuPath: '/license',
              menuName: 'License',
              componentPath: 'Licence/Licence.vue',
              requiresAuth: false
            }
          ]
      this.permissions = data.buttonVOList
      createDynamicRoutes(this.router)
    },
    async changeRouterAndPremission(teamId) {
      const data = await RouterMangerApi.getRouterByTeamId(teamId)
      this.router = data.menuVOList
      this.permissions = data.buttonVOList
      createDynamicRoutes(this.router)
    }
  }
})
