
import { routerAndPremission } from '@/store/routerAndPremission'
import { watch } from 'vue'
const permission = {
  // 当绑定元素插入到 DOM 中
  mounted(el, binding) {
    const displayKey = binding.value
    const routerAndPremission_store = routerAndPremission()
    watch(
      () => routerAndPremission_store.permissions,
      (newValue, oldValue) => {
        if (displayKey) {
          const hasPermission = newValue.includes(displayKey)
          if (!hasPermission) {
            if (displayKey === 'PERSONAL_CENTER') {
              // 单独处理个人中心
              el.style.display = 'none'
            } else {
              el.style.visibility = 'hidden'
            }
          } else {
            el.style.visibility = 'visible'
          }
        } else {
          throw new Error(`need key! Like v-permission = "''displayKey"`)
        }
      },
      {
        immediate: true
      }
    )
  }
}

export default permission
