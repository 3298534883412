export default {
  diseaseTypeAndFilterRange: 'AI筛查的病害类型及过滤范围',
  editDiseaseFilterRange: '修改病害过滤范围',
  filterRangeEmpty: '未设置过滤范围',
  screeningComplete: 'AI筛查完成',
  totalTime: '总共耗时',
  reflush: '手动刷新',
  allInspectionPhoto: '待AI筛查照片',
  allInspectionPhotoTips: '不包含手动已筛查照片',
  screenedPhoto: '已筛查照片',
  tobeReviewedPhoto: '待复查照片',
  diseasePhoto: '病害照片',
  diseaseMarkNum: '病害标记总数',
  AIDiseaseTotal: 'AI病害总数',
  diseaseFilterNum: '已过滤病害',
  diseaseNum: '病害数',
  all: '全部',
  tobeReviewed: '待复查',
  reviewed: '已复查',
  noDisease: '无病害',
  screendAbnormal: 'AI筛查异常',
  waitScreened: '待筛查',
  unscreened: '未筛查',
  confirmReview: '确认复查',
  confirmReviewText: '是否确认复查？',
  batchReview: '批量复查',
  stopScreening: '停止筛查',
  startScreening: '开始筛查',
  queueUp: 'AI筛查排队中',
  queueUpRate: '当前排第{num}位',
  jumpQueue: '插队',
  queueCancel: '取消排队',
  screeningCompleteText: 'AI筛查全部完成，请查看待复查的病害图片',
  filterRangeTitle: '调整病害过滤范围',
  filterRangeTips: '提示：请设置过滤范围，小于设置范围的病害不展示。',
  diseaseFilterConfigTitle: 'AI筛查的病害类型',
  picRateTitle: 'AI筛查的图片范围',
  picRangeTitle: '选择AI筛查的图片范围',
  diseaseTypeTitle: '勾选AI筛查的病害类型',
  adjustFilterRange: '调整过滤范围',
  diseaseCrackSizeText: '过滤长度<{length}m，或宽度<{width}mm的病害',
  diseaseOtherSizeText: '过滤面积<{area}m²的病害',
  batchReviewText: '确认复查会将你勾选的图片状态全部更新为已复查，是否继续？',
  picRateRadio1: '全部巡检图片（不删除已有的手动标记病害）',
  picRateRadio2: '全部巡检图片（删除已有的手动标记病害）',
  picRateRadio3: '仅待筛查的巡检图片',
  stopScreeningText: 'AI筛查还未完成，是否要停止筛查？',
  stopScreeningLoadingText: 'AI筛查停止中...',
  feedbackText: '误识别反馈',
  feedbackSubText: '你可以在此反馈AI误识别病害的问题',
  feedbackPlaceholder: '请输入AI误识别病害的问题',
  remainingTime: '预计剩余',
  selectPicRate: '选择AI筛查的图片范围',
  setBindingRange: '设置绑定范围',
  setBindingRangeSubText: '构件绑定病害的范围阈值（单位:m）',
  setBindingRangeTips: '病害绑定部件构件时，只绑定该范围的部件构件。',
  setBindingRangeEmpty: '病害范围不能为空',
  AIDisable: 'AI不可用',
  waitScreenedTitle: '“未被AI筛查过”的且筛查状态是“未筛查”或“存疑”的图片',
  tobeReviewedTitle: '“被AI筛查过”的，且复查状态为“待复查”的图片',
  reviewedTitle: '复查状态为“已复查”的图片',
  inspectionImgDeleteTips: '确定要删除此巡检图片?',
  bindSpaceMark: '前往绑定',
  directRunScreening: '继续筛查',
  noSpaceMarkTips: '有{count}张照片未绑定部件构件，继续筛查病害的部件构件数据将为空，是否继续？',
  moreSpaceMarkerTips: '绑定了多个部件构件',
  errorTips: 'AI服务异常',
  diseaseFiltration: '病害过滤',
  // AI筛查状态
  NOT_EXISTING: 'AI筛查',
  AI_CHECKING: 'AI筛查中',
  AI_QUEUE: 'AI筛查排队中',
  AI_CHECK_EXCEPTION: 'AI筛查异常',
  AI_SERVER_EXCEPTION: 'AI服务异常',
  AI_CHECK_COMPLETE: 'AI筛查已完成',
  AI_In_Preparation: 'AI准备中',
  TASK_CANCELING: 'AI取消中',

  // AI筛查出错结果
  NO_INSPECTION_FOR_THE_IMAGE: '未查询到图片所属巡检信息',
  PROJECT_MODEL_ERROR_FOR_THE_IMAGE: '未查询到图片所属项目模型或项目模型中心点不完整',
  IMAGE_ERROR_FOR_THE_DISEASE: '未查询到病害的图片信息或图片信息不完整',
  IMAGE_PX_LENGTH_CALCULATION_ERROR: '图片像素长度计算错误',
  DISEASE_TYPE_NOT_COVER_AI: '病害类型未覆盖AI筛查结果类型',
  DISEASE_IMAGE_URL_NULL: '病害图片的URL为空',
  AI_RESULT_SAVE_ERROR: 'AI结果保存异常',
  TWO_D_TO_3D_FAIL: '二维坐标转三维坐标异常',
  AI_SERVER_CHECK_ERROR: 'AI服务校验异常'
}
