export default {
  personalCenter: 'Personal Center',
  logout: 'Log Out',
  name: 'Name',
  email: 'Email',
  phone: 'Phone Number',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  changePassword: 'Change Password',
  unbinded: 'Not bound',
  bind: 'Bind',
  unbind: 'Unbind',
  wechat: 'WeChat',
  unbindWechat: 'Unbind WeChat',
  unit: 'Company/Shcool',
  job: 'Your Position',
  avatarTips: 'Supports jpg and png formats, with a size limit of 3MB',
  view: 'Previews',
  editAvatar: 'Change Avatar',
  editName: 'Change Name',
  editPhone: 'Change Phone Number',
  editEmail: 'Change Email',
  emailVerify: 'Email Verification',
  newEmail: 'New Email',
  emailVerifyCode: 'Email Verification Code',
  emailVerifyCodePlaceholder: 'Please enter the email verification code',
  emailVerifyCodeTips: 'The verification code has been sent to your email.',
  passwordVerify: 'Password verification',
  oldPasswordPlaceholder: 'Please enter the old password',
  newPasswordPlaceholder: 'Please enter the new password.',
  confirmPasswordPlaceholder: 'Please re-enter the new password',
  wechatBind: 'Bind WeChat',
  scanQRCode: 'Scan QR code with WeChat to bind',
  wechatTips: 'Future support for WeChat QR code login',
  editUnit: 'Change Company/Position',
  editNamePlaceholder: 'Please enter your name',
  verifyPhone: 'Phone Verification',
  smsVerifyCode: 'SMS verification code',
  smsPlaceholder: 'Please enter the SMS verification code',
  newPhoneNumber: 'New Phone Number',
  newPhoneNumberPlaceholder: 'Please enter the new phone number',
  errorPhoneNum: 'Please enter a valid phone number',
  currentPassword: 'Current Password',
  currentPasswordPlaceholder: 'Please enter the current password.',
  setNewPassword: 'Set New Password',
  confirmPassword: 'Confirm New Password',
  passwordPlaceholder: 'Please enter the password.',
  inUnit: 'Company/Shcool',
  inUnitPlaceholder: 'Enter the name of the affiliated company or school',
  inJob: 'Position',
  inJobPlaceholder: 'Please enter your position',
  editSuccess: 'Successfully Updated',
  varifySuccess: 'Verification Successful',
  avatarTypeTips: 'Only jpg and png formats are allowed',
  avatarSizeTips: 'Image size should not exceed 3MB',
  avatarUploadFail: 'Avatar Upload Failed',
  avatarUploadSuccess: 'Avatar Upload Successful',
  reUpload: 'Re-Upload',
  uploadAvatar: 'Upload Avatar',
  changeLanguage: 'Switch Language'
}
