export default {
  historyDisease: 'Defect evolution',
  mouseTips: 'Tip: Drag the image with the left mouse button; Roller amplification/reduction; Hold down Ctrl+ left or right mouse button to adjust the center of the image。',

  centerMarker: 'Center point',
  disease: 'Defect',
  diseaseList: 'Defect list',
  diseaseSize: 'Size of defect',
  diseaseLevel: 'Level of defect',
  diseaseDetail: 'Details of defect',
  diseaseDes: 'Description of defect',
  markerTime: 'Marker / Date',
  spacialMarker: 'Spatial marker',
  bind: 'Bound',
  reDrawDisease : 'Redraw'

}
