import request from '../axios.js'

export default {
  getDiseaseUserDesignDicts : async(data) => {
    const res = await request({
      url: `/project-custom/findAllCustom`,
      method: 'POST',
      data
    })
    return res || []
  },
  //  获取字典
  getDictionaryByTag: async(data) => {
    const res = await request({
      url: `/dictionary-data/findData`,
      method: 'POST',
      data
    })
    return res
  },
  getLocationNumbers: async(data) => {
    return request({
      url: '/dictionary-data/register-location',
      method: 'GET'
    })
  }
}
