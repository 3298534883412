
import request from '../axios.js'

export default {
  async getRouterTree(roleId) {
    const res = await request({
      url: `/role-menu/${roleId}`,
      method: 'GET'
    })
    return res
  },
  async getRouterByTeamId(teamId) {
    const res = await request({
      url: `/role-menu/changeTeam/${teamId}`,
      method: 'GET'
    })
    return res
  }
}
