
export default {
  recycleBin: 'Data Recycle Bin',
  subtitle: 'Support data recovery, you can also permanently delete',
  restoreAll: 'Batch restore',
  completelyDeleteAll: 'Batch permanently delete',
  cleanRecycleBin: 'Empty recycle bin',
  searchPlaceholder: 'Search data',
  filterType: 'Filter type',
  serialNumber: 'Serial number',
  dataName: 'Data name',
  dataType: 'Type',
  fileSize: 'File size',
  operator: 'Operator',
  deleteDate: 'Delete date',
  operation: 'Operation',
  stored: 'Recycle bin occupied space',
  completelyDelete: 'Completely delete',
  completeDeletePlaceholder: 'Are you sure you want to delete this data? It will not be recoverable.',
  restore: 'Restore',
  dataNameTips: 'This project contains project basic information、project model、inspection information、disease information and inspection report.'

}
