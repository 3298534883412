import request from '../axios.js'

export default {
  // 平铺查询所有空间标记根节点
  getSpaceNodeByProject: async(data) => {
    const res = await request({
      url: `space-marking/temporary/find/space/group/bind/node`,
      method: 'POST',
      data
    })
    return res || []
  },
  // 平铺查询空间标记点
  getSpaceMarkerByProject: async(params) => {
    const res = await request({
      url: `/space-marking/img-space-marking`,
      method: 'GET',
      params
    })
    return res
  },
  // 批量查询照片下的病害点位 (历史对比)
  getDiseasesByImageIds: async(data) => {
    const res = await request({
      url: `/inspection-disease/list/imgS`,
      method: 'POST',
      data
    })
    return res
  },
  // 空间标记树结构
  findTree: async(data) => {
    const res = await request({
      url: `/space-marking/findTree`,
      method: 'POST',
      data
    })
    return res
  },
  spaceMakeFindList: async(data) => {
    const res = await request({
      url: `/inspection-task/spaceMakeFindList`,
      method: 'POST',
      data
    })
    return res
  },
  // 空间点位下所有巡检相关联的巡检照片
  findSpaceImages: async(data) => {
    const res = await request({
      url: `/space-image-assoc/findSpaceImages`,
      method: 'POST',
      data
    })
    return res
  },
  // 删除空间点位下绑定巡检图片

  deletesIds: async(data) => {
    const res = await request({
      url: `/space-image-assoc/deletes/ids`,
      method: 'DELETE',
      data
    })
    return res
  },
  // 绑定计算巡检图片
  bindSpaceImages: async(data) => {
    const res = await request({
      url: `/space-image-assoc/adds`,
      method: 'POST',
      data
    })
    return res
  },
  // 巡检照片查询
  findInspectionAllImages: async(id) => {
    const res = await request({
      url: `/inspection-image/findInspectionAllImages/${id}`,
      method: 'POST'
    })
    return res
  },
  addOrUpdate: async(data) => {
    const res = await request({
      url: `/space-marking/addOrUpdate`,
      method: 'POST',
      data
    })
    return res
  },
  deleteGroupById: async(id) => {
    const res = await request({
      url: `/space-marking/${id}`,
      method: 'DELETE'
    })
    return res
  },
  findChildList: async(id) => {
    const res = await request({
      url: `/space-marking/findChildList`,
      method: 'POST',
      data: { projectId: id }
    })
    return res
  },
  findSpaceList: async(id) => {
    const res = await request({
      url: `/space-image-assoc/findSpaceList`,
      method: 'POST',
      data: { imageId: id }
    })
    return res
  },
  // 删除所有巡检绑定照片
  deleteBindImages: async(data) => {
    const res = await request({
      url: `/space-image-assoc/delete/spaceAssoceDelete`,
      method: 'DELETE',
      data
    })
    return res
  },
  // 删除当前空间点绑定的所有巡检照片
  deleteBindImageBySpaceId: async(data) => {
    const res = await request({
      url: `/space-image-assoc/delete/spaceAssoceDelete`,
      method: 'DELETE',
      data
    })
    return res
  },
  getNoSpaceMarking: async(params) => {
    const res = await request({
      url: `/inspection-image/no-space-marking/infos`,
      method: 'GET',
      params
    })
    return res
  },
  getProjectInfoById: async(projectId) => {
    const res = await request({
      url: `/project-model/findByProjectId/${projectId}`,
      method: 'GET'
    })
    return res
  },
  getUnbindedImgs: async(data) => {
    const res = await request({
      url: `/space-image-assoc/find/images/no/bind/img`,
      method: 'POST',
      data
    })
    return res
  }

}
