
import { i18n } from '@/utils/i18n'
import axios from 'axios'
import { setMsg, MsgType } from '@/utils/messageUtil'
import { TokenStorage } from '@/utils/storageUtil'
import router from '@/router'
const { tm, t } = i18n.global

const timeout = 90000

export const BASE_URL = window.baseUrl

export const resCheck = (res) => {
  if (!res) return
  const { code, data } = res
  if (code * 1 === 2000) {
    return data
  }
}

const getMsg = (res) => {
  const { msgKey, msgFill } = res
  if (!msgKey) return ''
  let msg = tm(`api.${msgKey}`)
  if (msgFill) {
    // TODO 需要确定 msgFill 和 msgKey 的对应关系
    msg = t(`api.${msgKey}`, msgFill)
  }
  return msg
}
// 系统警告
export const setWarningMsg = (res) => {
  const msg = getMsg(res)
  msg && setMsg(msg, MsgType.WARNING)
}
// 系统错误
export const setErrorMsg = (res) => {
  const msg = getMsg(res)
  msg && setMsg(msg, MsgType.ERROR)
}

/**
 *
 * @param {Object} reqOptions 请求参数
 * @param {Object} options 请求配置
 * @param {String} baseURL 请求域名地址
 * @returns
 */
const request = (reqOptions, options, baseURL = BASE_URL) => {
  const service = axios.create({
    baseURL,
    timeout
  })
  // request interceptor
  service.interceptors.request.use(
    (config) => {
      config.headers['rieboToken'] = TokenStorage.get()
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  service.interceptors.response.use(
    (response) => {
      const res = response.data
      // 若是返回的类型不为json, 此接口不是普通数据接口
      if (response.headers['content-type'] && !response.headers['content-type'].includes('application/json')) {
        return response
      }
      if (res.code * 1 !== 2000) {
        switch (res.code) {
          case '5000':
            setMsg(tm('api.serviceError'), MsgType.ERROR)
            break
          case '4000':
            setErrorMsg(res)
            break
          case '4001':
            // 接口没有权限
            setMsg(tm('api.NO_AUTH'), MsgType.WARNING)
            break
          case '4003':
            // 登录失效 failure
            setMsg(tm('api.LOGIN_FAILURE'), MsgType.WARNING)
            localStorage.removeItem('rieboToken')
            router.push('/login')
            break
          case '2003':
            setWarningMsg(res)
            break
        }
        return Promise.reject(res)
      }
      return resCheck(res)
    },
    (error) => {
      let message
      if (error.toString() === 'Error: Network Error') {
        if (window.navigator.onLine) {
          message = tm('api.serviceError')
        } else {
          message = tm('api.offLine')
        }
      } else if (error.toString() === `Error: timeout of ${timeout}ms exceeded`) {
        message = tm('api.timeout')
      } else if (error.toString() === `AxiosError: Request failed with status code 404`) {
        message = tm('api.notFound')
      } else {
        message = tm('api.serviceError')
      }
      setMsg(message, MsgType.ERROR)

      return Promise.reject(error)
    }
  )
  return service(reqOptions)
}

export default request
