import disease_zh_CN from './disease_zh-CN'
export default {
    // 病害标度
    DISEASE_1: '1级 完好',
    DISEASE_2: '2级 轻微',
    DISEASE_3: '3级 一般',
    DISEASE_4: '4级 大范围',
    DISEASE_5: '5级 严重',
    // 项目类型
    PROJECT_TYPE_BRIDGE: '桥梁',
    PROJECT_TYPE_ROAD: '道路',
    PROJECT_TYPE_BULID: '建筑',
    PROJECT_TYPE_DAM: '大坝',
    PROJECT_TYPE_TEST: '测试',
    PROJECT: '项目',
    INSPECTION: '巡检',
    // 病害类型:
    ...disease_zh_CN,
    // DISEASE_CRACK: '裂缝',
    // DISEASE_SPALLING: '剥落',
    // DISEASE_RUST: '锈蚀',
    // DISEASE_DEFORM: '变形',
    // DISEASE_EMPTY: '空洞',
    // DISEASE_WORN: '破损',
    // DISEASE_REVEAL_REIN: '露筋',
    // DISEASE_BURN_IN: '老化',

    // 用户岗位
    CORP_MANAGEMENT: '单位管理层',
    CORP_SALES_MANAGER: '单位销售经理',
    CORP_CLERK: '单位普通职员',
    SCHOOL_TEACHER: '学校老师',
    SCHOOL_STUDENT: '在校学生',

    // 报告错误信息
    NO_DISEASE_ERROR: '没有病害',
    DISEASE_EXPORT_ERROR: '病害数超过上限导出失败',
    INSPECTION_IMAGE_NOT_EXISTING: '巡检照片不存在',
    IMAGES_DRAW_ERROR: '图片绘制时失败',

    // 国家、地区
    China: '中国大陆',
    Afghanistan: '阿富汗',
    Albania: '阿尔巴尼亚',
    Algeria: '阿尔及利亚',
    AmericanSamoa: '美属萨摩亚',
    Andorra: '安道尔',
    Angola: '安哥拉',
    Anguilla: '安圭拉',
    AntiguaAndBarbuda: '安提瓜和巴布达',
    Argentina: '阿根廷',
    Armenia: '亚美尼亚',
    Aruba: '阿鲁巴',
    Australia: '澳大利亚',
    Austria: '奥地利',
    Azerbaijan: '阿塞拜疆',
    Bahamas: '巴哈马',
    Bahrain: '巴林',
    Bangladesh: '孟加拉国',
    Barbados: '巴巴多斯',
    Belarus: '白俄罗斯',
    Belgium: '比利时',
    Belize: '伯利兹',
    Benin: '贝宁',
    Bermuda: '百慕大群岛',
    Bhutan: '不丹',
    Bolivia: '玻利维亚',
    BosniaAndHerzegovina: '波斯尼亚和黑塞哥维那',
    Botswana: '博茨瓦纳',
    Brazil: '巴西',
    Brunei: '文莱',
    Bulgaria: '保加利亚',
    BurkinaFaso: '布基纳法索',
    Burundi: '布隆迪',
    Canada: '加拿大',
    Cambodia: '柬埔寨',
    Cameroon: '喀麦隆',
    CaymanIslands: '开曼群岛',
    CentralAfricanRepublic: '中非共和国',
    IvoryCoast: '科特迪瓦',
    Chad: '乍得',
    Chile: '智利',
    Colombia: '哥伦比亚',
    CapeVerde: '佛得角',
    Comoros: '科摩罗',
    CookIslands: '库克群岛',
    CostaRica: '哥斯达黎加',
    Croatia: '克罗地亚',
    Cyprus: '塞浦路斯',
    Czech: '捷克',
    Denmark: '丹麦',
    Djibouti: '吉布提',
    Dominica: '多米尼加',
    DominicanRepublic: '多米尼加共和国',
    Ecuador: '厄瓜多尔',
    Egypt: '埃及',
    ElSalvador: '萨尔瓦多',
    EquatorialGuinea: '赤道几内亚',
    Eritrea: '厄立特里亚',
    Estonia: '爱沙尼亚',
    Ethiopia: '埃塞俄比亚',
    FaroeIslands: '法罗群岛',
    Fiji: '斐济',
    Finland: '芬兰',
    France: '法国',
    FrenchGuiana: '法属圭亚那',
    FrenchPolynesia: '法属玻利尼西亚',
    Gabon: '加蓬',
    Gambia: '冈比亚',
    Georgia: '格鲁吉亚',
    Germany: '德国',
    Ghana: '加纳',
    Gibraltar: '直布罗陀',
    Greece: '希腊',
    Greenland: '格陵兰岛',
    Grenada: '格林纳达',
    Guadeloupe: '瓜德罗普岛',
    Guam: '关岛',
    Guatemala: '危地马拉',
    Guinea: '几内亚',
    GuineaBissau: '几内亚比绍共和国',
    Guyana: '圭亚那',
    Haiti: '海地',
    Honduras: '洪都拉斯',
    HongKongChina: '中国香港',
    Hungary: '匈牙利',
    Iceland: '冰岛',
    India: '印度',
    Indonesia: '印度尼西亚',
    Iraq: '伊拉克',
    Ireland: '爱尔兰',
    Israel: '以色列',
    Italy: '意大利',
    Jamaica: '牙买加',
    Japan: '日本',
    Jordan: '约旦',
    Kazakhstan: '哈萨克斯坦',
    Kenya: '肯尼亚',
    Kiribati: '基里巴斯',
    Kuwait: '科威特',
    Kyrgyzstan: '吉尔吉斯斯坦',
    Laos: '老挝',
    Latvia: '拉脱维亚',
    Lebanon: '黎巴嫩',
    Lesotho: '莱索托',
    Liberia: '利比里亚',
    Libya: '利比亚',
    Liechtenstein: '列支敦士登',
    Lithuania: '立陶宛',
    Luxembourg: '卢森堡',
    MacauChina: '中国澳门',
    Macedonia: '马其顿',
    Madagascar: '马达加斯加',
    Malawi: '马拉维',
    Malaysia: '马来西亚',
    Maldives: '马尔代夫',
    Mali: '马里',
    Malta: '马耳他',
    Martinique: '马提尼克岛',
    MarianaIslands: '马里亚纳群岛',
    Mauritania: '毛里塔尼亚',
    Mauritius: '毛里求斯',
    Mayotte: '马约特',
    Mexico: '墨西哥',
    Moldova: '摩尔多瓦',
    Monaco: '摩纳哥',
    Mongolia: '蒙古',
    Montenegro: '黑山',
    Montserrat: '蒙特塞拉特',
    Morocco: '摩洛哥',
    Mozambique: '莫桑比克',
    Myanmar: '缅甸',
    Namibia: '纳米比亚',
    Nepal: '尼泊尔',
    Netherlands: '荷兰',
    NetherlandsAntilles: '荷属安的列斯群岛',
    NewCaledonia: '新喀里多尼亚',
    NewZealand: '新西兰',
    Nicaragua: '尼加拉瓜',
    Niger: '尼日尔',
    Nigeria: '尼日利亚',
    Norway: '挪威',
    NorfolkIsland: '诺福克岛',
    Oman: '阿曼',
    Pakistan: '巴基斯坦',
    Palau: '帕劳',
    Palestine: '巴勒斯坦',
    Panama: '巴拿马',
    PapuaNewGuinea: '巴布亚新几内亚',
    Paraguay: '巴拉圭',
    Peru: '秘鲁',
    Philippines: '菲律宾',
    Poland: '波兰',
    Portugal: '葡萄牙',
    PuertoRico: '波多黎各',
    Qatar: '卡塔尔',
    RepublicOfTheCongo: '刚果共和国',
    TheDemocraticRepublicOfTheCongo: '刚果民主共和国（扎伊尔）',
    Romania: '罗马尼亚',
    Russia: '俄罗斯',
    Rwanda: '卢旺达',
    SaintKittsandNevis: '圣基茨和尼维斯',
    SaintLucia: '圣卢西亚',
    SaintPierreAndMiquelon: '圣皮埃尔和密克隆',
    SaintVincentAndTheGrenadines: '圣文森特和格林纳丁斯',
    Samoa: '萨摩亚',
    SanMarino: '圣马力诺',
    SaoTomeAndPrincipe: '圣多美和普林西比',
    SaudiArabia: '沙特阿拉伯',
    Senegal: '塞内加尔',
    Serbia: '塞尔维亚',
    Seychelles: '塞舌尔',
    SierraLeone: '塞拉利昂',
    Singapore: '新加坡',
    SaintMaartenDutchPart: '圣马丁岛',
    Slovakia: '斯洛伐克',
    Slovenia: '斯洛文尼亚',
    SolomonIslands: '所罗门群岛',
    Somalia: '索马里',
    SouthAfrica: '南非',
    SouthKorea: '韩国',
    Spain: '西班牙',
    SriLanka: '斯里兰卡',
    Suriname: '苏里南',
    Swaziland: '斯威士兰',
    Sweden: '瑞典',
    Switzerland: '瑞士',
    TaiwanChina: '中国台湾',
    Tajikistan: '塔吉克斯坦',
    Tanzania: '坦桑尼亚',
    Thailand: '泰国',
    TimorLeste: '东帝汶',
    TheRepublicOfTheSudan: '苏丹',
    TheRepublicOfSouthSudan: '南苏丹',
    TheFederatedStatesOfMicronesia: '密克罗尼西亚',
    Togo: '多哥',
    Tonga: '汤加',
    TrinidadAndTobago: '特立尼达和多巴哥',
    Tunisia: '突尼斯',
    Turkey: '土耳其',
    Turkmenistan: '土库曼斯坦',
    TurksAndCaicosIslands: '特克斯和凯科斯群岛',
    Uganda: '乌干达',
    Ukraine: '乌克兰',
    UnitedArabEmirates: '阿拉伯联合酋长国',
    UnitedKingdom: '英国',
    UnitedStates: '美国',
    Uruguay: '乌拉圭',
    Uzbekistan: '乌兹别克斯坦',
    Vanuatu: '瓦努阿图',
    Venezuela: '委内瑞拉',
    Vietnam: '越南',
    VirginIslandsBritish: '英属维尔京群岛',
    VirginIslandsUS: '美属维尔京群岛',
    Yemen: '也门',
    Zambia: '赞比亚',
    Zimbabwe: '津巴布韦'
}