
export default {
  feedbackTitle: 'Thanks for your feedback!',
  feedbackSubTitle: 'What issue would you like to provide feedback on?',
  suggest: 'Suggestions',
  softwareBug: 'Software bug',
  feedbackPlaceholderSuggest: 'Please enter your suggestions or desired features after using the software.',
  feedbackPlaceholderSoftwareBug: 'Please enter the software issues (BUG) you encountered',
  upgrade: 'Software Upgrade',
  recycleBin: 'Recycle Bin'
}
