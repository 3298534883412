import disease_en from './disease_en'
export default {
    DISEASE_1: 'Level one(Intact)',
    DISEASE_2: 'Level two(Slight)',
    DISEASE_3: 'Level three(Normal)',
    DISEASE_4: 'Level four(Large area)',
    DISEASE_5: 'Level five(Serious degree)',
    // 桥梁类型
    PROJECT_TYPE_BRIDGE: 'Bridges',
    PROJECT_TYPE_ROAD: 'Roads',
    PROJECT_TYPE_BULID: 'Buildings',
    PROJECT_TYPE_DAM: 'Dam',
    PROJECT: 'Project',
    INSPECTION: 'Inspection',
    // 病害类型
    ...disease_en,
    // DISEASE_CRACK: 'Crack',
    // DISEASE_SPALLING: 'Peel off',
    // DISEASE_RUST: 'Corrosion',
    // DISEASE_DEFORM: 'Amorphosis',
    // DISEASE_EMPTY: 'Hollow',
    // DISEASE_WORN: 'Worn',
    // DISEASE_REVEAL_REIN: 'Exposed bar',
    // DISEASE_BURN_IN: 'Aging',

    // 用户岗位
    CORP_MANAGEMENT: 'Supervisor',
    CORP_SALES_MANAGER: 'Sales Manager',
    CORP_CLERK: 'Other Positions',
    SCHOOL_TEACHER: 'School Teachers',
    SCHOOL_STUDENT: 'Student',

    // 报告错误信息
    NO_DISEASE_ERROR: 'No disease in this inspection',
    DISEASE_EXPORT_ERROR: 'The number of diseases exceeds the upper limit',
    INSPECTION_IMAGE_NOT_EXISTING: 'Inspection photo does not exist',
    IMAGES_DRAW_ERROR: 'Error drawing images',

    // 国家、地区
    China: 'Mainland China',
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algeria',
    AmericanSamoa: 'American Samoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    AntiguaAndBarbuda: 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    BosniaAndHerzegovina: 'Bosnia and Herzegovina',
    Botswana: 'Botswana',
    Brazil: 'Brazil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgaria',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    Canada: 'Canada',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    CaymanIslands: 'Cayman Islands',
    CentralAfricanRepublic: 'Central African Republic',
    IvoryCoast: `Cote d'Ivoire`, // TOOD
    Chad: 'Chad',
    Chile: 'Chile',
    Colombia: 'Colombia',
    CapeVerde: 'Cape Verde',
    Comoros: 'Comoros',
    CookIslands: 'Cook Islands',
    CostaRica: 'Costa Rica',
    Croatia: 'Croatia',
    Cyprus: 'Cyprus',
    Czech: 'Czech Republic',
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    DominicanRepublic: 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    ElSalvador: 'El Salvador',
    EquatorialGuinea: 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Ethiopia',
    FaroeIslands: 'Faroe Islands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    FrenchGuiana: 'French Guiana',
    FrenchPolynesia: 'French Polynesia',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guinea: 'Guinea',
    GuineaBissau: 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    Honduras: 'Honduras',
    HongKongChina: 'Hong Kong, China',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    MacauChina: 'Macau, China',
    Macedonia: 'Macedonia',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    Martinique: 'Martinique',
    MarianaIslands: 'Mariana Islands',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Mexico: 'Mexico',
    Moldova: 'Moldova',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    NetherlandsAntilles: 'Netherlands Antilles',
    NewCaledonia: 'New Caledonia',
    NewZealand: 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Norway: 'Norway',
    NorfolkIsland: 'Norfolk Island',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    Palestine: 'Palestine',
    Panama: 'Panama',
    PapuaNewGuinea: 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Poland: 'Poland',
    Portugal: 'Portugal',
    PuertoRico: 'Puerto Rico',
    Qatar: 'Qatar',
    RepublicOfTheCongo: 'Republic of the Congo',
    TheDemocraticRepublicOfTheCongo: 'Democratic Republic of the Congo (Zaire)',
    Romania: 'Romania',
    Russia: 'Russia',
    Rwanda: 'Rwanda',
    SaintKittsandNevis: 'St. Kitts and Nevis',
    SaintLucia: 'St. Lucia',
    SaintPierreAndMiquelon: 'St. Pierre and Miquelon',
    SaintVincentAndTheGrenadines: 'St. Vincent and the Grenadines',
    Samoa: 'Samoa',
    SanMarino: 'San Marino',
    SaoTomeAndPrincipe: 'Sao Tome and Principe',
    SaudiArabia: 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    SierraLeone: 'Sierra Leone',
    Singapore: 'Singapore',
    SaintMaartenDutchPart: 'St. Maarten (Dutch part)',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    SolomonIslands: 'Solomon Islands',
    Somalia: 'Somalia',
    SouthAfrica: 'South Africa',
    SouthKorea: 'South Korea',
    Spain: 'Spain',
    SriLanka: 'Sri Lanka',
    Suriname: 'Suriname',
    Swaziland: 'Swaziland',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    TaiwanChina: 'Taiwan, China',
    Tajikistan: 'Tajikistan',
    Tanzania: 'Tanzania',
    Thailand: 'Thailand',
    TimorLeste: 'Timor-Leste',
    TheRepublicOfTheSudan: 'Sudan',
    TheRepublicOfSouthSudan: 'South Sudan',
    TheFederatedStatesOfMicronesia: 'Micronesia',
    Togo: 'Togo',
    Tonga: 'Tonga',
    TrinidadAndTobago: 'Trinidad and Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    TurksAndCaicosIslands: 'Turks and Caicos Islands',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    UnitedArabEmirates: 'United Arab Emirates',
    UnitedKingdom: 'United Kingdom',
    UnitedStates: 'United States',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    VirginIslandsBritish: 'Virgin Islands (British)',
    VirginIslandsUS: 'Virgin Islands, U.S.',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe'

}