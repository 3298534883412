const blankSpaceCheck = {
  mounted(el) {
    const inputHandler = el.childNodes[0].parentNode.querySelector('input')
    inputHandler.addEventListener('keydown', (e) => {
      if (inputHandler instanceof HTMLInputElement) {
        if (inputHandler.value === '' && e.keyCode === 32) {
          inputHandler.value = inputHandler.value.trim()
          e.preventDefault()
        }
        return
      }
    })
    inputHandler.addEventListener('keyup', (e) => {
      if (inputHandler instanceof HTMLInputElement) {
        if (inputHandler.value === ' ' || inputHandler.value.startsWith(' ')) {
          inputHandler.value = inputHandler.value.trim()
          e.preventDefault()
        }
        return
      }
    })
    inputHandler.addEventListener('blur', (e) => {
      if (inputHandler.value.endsWith(' ')) {
        inputHandler.value = inputHandler.value.trim()
      }
    })
  }
}

export default blankSpaceCheck
