/*
 * @Author: zhengqj
 * @Date: 2024-06-04 10:08:02
 * @LastEditors: zhengqj
 * @LastEditTime: 2024-06-21 19:00:41
 * @Description: file content
 * @WebSite: http://webgis.fun
 */

import { createApp } from 'vue'
import App from './App.vue'

// i18n
import { i18n } from './utils/i18n'

// element
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

// 懒加载
import Lazyload from 'vue3-lazyload'
// 分辨率适配
import 'amfe-flexible'

// 样式
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'
import '@/assets/less/index.less'

// store
import pinia from './store'
import router from './router'

import { ClickOutside } from 'element-plus'

// 禁用调试
import { useBanShortcutKey, useDubggerDefense } from './utils/safeDefense.js'

if (process.env.NODE_ENV === 'production') {
  useBanShortcutKey()
  useDubggerDefense()
}

const app = createApp(App)
  .use(pinia)
  .use(i18n)
  .use(ElementPlus)
  .use(router)
  .use(Lazyload)

// 全局指令
import { draggable, blankSpaceCheck, permission } from './directives/'
app.directive('draggable', draggable)
app.directive('blankSpaceCheck', blankSpaceCheck)
app.directive('permission', permission)
app.directive('clickOutside', ClickOutside)
app.mount('#app')

