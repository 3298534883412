export default {
  notice: '通知',
  maintenance: '维护通知',
  renewal: '到期续费',
  newFeature: '新功能',
  clearAll: '清空',
  markRead: '全部已读',
  noNotice: '暂无通知',
  systemNotic: '系统通知'
}
