import request from '../axios.js'

export default {
    // 删除图片
    deleteImage: async(id) => {
        const res = await request({
            url: `/inspection-image/${id}`,
            method: 'DELETE'
        })
        return true
    },
    // 根据ID查询图片
    getInspectionImage: async(id) => {
        return request({
            url: `/inspection-image/${id}`,
            method: 'GET'
        })
    },
    // 查询单个巡检下的照片-临时增加
  findInspectionAllImages_new: async(data) => {
    const res = await request({
      url: `/inspection-image/find/inspection/images`,
      method: 'POST',
      data
    })
    return res || []
  },
    // 查询单个巡检下的照片
    findInspectionAllImages: async(inspectionId) => {
        const res = await request({
            url: `/inspection-image/findInspectionAllImages/${inspectionId}`,
            method: 'POST'
        })
        return res || []
    },
    // 查询单个巡检下的照片
    getImagesByInspection: async(data) => {
        const res = await request({
            url: `/inspection-image/findPage`,
            method: 'POST',
            data
        })
        return res || {
            records: [],
            total: 0
        }
    },
    // 创建巡检任务
    inspectionAdd: async(data) => {
        const res = await request({
            url: `/inspection-task/add`,
            method: 'POST',
            data
        })
        return res
    },
    // 更新巡检任务
    inspectionUpdate: async(data) => {
        const res = await request({
            url: `/inspection-task`,
            method: 'PUT',
            data
        })
        return res
    },
    // 获取巡检图片上传详情
    inspectionFindImages: async(id) => {
        const res = await request({
            url: `/inspection-image/findImages/${id}`,
            method: 'POST'
        })
        return res
    },
    // 相机配置
    cameraList: async() => {
        const res = await request({
            url: '/camera-param/list',
            method: 'GET'
        })
        return res
    },
    // 导入相机配置excel
    importCamera: async(data) => {
        const res = await request({
            url: '/camera-param/import',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data
        })
        return res
    },
    // 提交上传图片信息
    inspectionImage: async(data) => {
        console.log(data)
        const res = await request({
            url: '/inspection-image',
            method: 'POST',
            data
        })
        return res
    },

    // 删除巡检下的图片
    inspectionImageDelete: async(fileId) => {
        const res = await request({
            url: `/inspection-image/fileId/${fileId}`,
            method: 'DELETE'
        })
        return res
    },
    // 修改巡检图片信息
    imspectionImageUpdate: async(data) => {
        const res = await request({
            url: '/inspection-image/updateDiseaseByImgFile',
            method: 'POST',
            data
        })
        return res
    },
    // 查询项目列表
    projectList: async(data) => {
        const res = await request({
            url: '/project/list',
            method: 'GET',
            data
        })
        return res
    },
    // 巡检列表查询
    inspectionList: async(params) => {
        const res = await request({
            url: '/inspection-task/list',
            method: 'GET',
            params
        })
        return res
    },
    inspectionBriefinfo: async(params) => {

    },
    // 巡检任务删除
    inspectionDelete: async(id) => {
        const res = await request({
            url: `/inspection-task/${id}`,
            method: 'DELETE'
        })
        return res
    },
    // 查询巡检任务详情
    inspectionInfo: async(id) => {
        const res = await request({
            url: `/inspection-task/${id}`,
            method: 'GET'
        })
        return res
    },
    // 巡检报告查看
    insDocPreview: async(filePath) => {
        const res = await request({
            url: filePath,
            method: 'get',
            responseType: 'blob'
        })

        return res
    },
    // 创建报告
    createInspectionReport: async(data) => {
        const res = await request({
            url: '/inspection-report/createInspectionReport',
            method: 'POST',
            data
        })
        return res
    },
    reCreateInspectionReport: async(data) => {
        const res = await request({
            url: '/inspection-report/again/data',
            method: 'POST',
            data
        })
        return res
    },
    // 查询巡检报告
    insDocList: async(data) => {
        const res = await request({
            url: '/inspection-report/findReportList',
            method: 'POST',
            data
        })
        return res
    },
    insDocDelete: async(id) => {
        const res = await request({
            url: `/inspection-report/${id}`,
            method: 'DELETE'
        })
        return res
    },
    // 查询病害图片分页列表
    diseaseListPages: async(data) => {
        return await request({
            url: `/inspection-disease/page/inspect?page=${data.page}&size=${data.size}`,
            method: 'POST',
            data
        })
    },
    // 查询病害列表
    diseaseList: async(data) => {
        return await request({
            url: '/inspection-disease/list/inspect',
            method: 'POST',
            data
        })
    },

    // 查询病害类型
    diseaseTypeList: async(params) => {
        return await request({
            url: '/disease-type/list',
            method: 'GET',
            params
        })
    },
    // 导出病害EXCEL
    diseaseExport: async(data) => {
        return await request({
            url: '/inspection-disease/excel',
            responseType: 'arraybuffer',
            method: 'POST',
            data
        })
    },
    // 删除病害
    diseaseDelete: async(id) => {
        return await request({
            url: `/inspection-disease/${id}`,
            method: 'DELETE'
        })
    },
    // 更新病害
    diseaseUpdate: async(data) => {
        return await request({
            url: '/inspection-disease',
            method: 'PUT',
            data
        })
    },
    async inspectionTaskInfo(params) {
        return request({
            url: '/inspection-task/info',
            method: 'GET',
            params
        })
    },
    // 查询病害自定义字段
    findDiseaseCustomField(data) {
        return request({
            url: '/project-custom/findAllCustom',
            method: 'POST',
            data
        })
    },
    // 查询项目的自定义类型
    getProjectFiled(data) {
        return request({
            url: '/project-filed/find/project/filed',
            method: 'POST',
            data
        })
    },
    // 查询病害字段值
    getTenData(data) {
        return request({
            url: '/inspection-report/find/ten/data',
            method: 'POST',
            data
        })
    },
    // #region 巡检报告配置项相关
    // 获取部件构件标记点组
    getSpaceMarkingList: async(data) => {
        return await request({
            url: '/space-marking/find/project/all/space/marking',
            method: 'POST',
            data
        })
    },
    // 部件构件tree
    getPartTreeData: async(data) => {
        return await request({
            url: `/space-marking/findTree`,
            method: 'POST',
            data
        })
    },
    // 病害类型
    getDiseaseTypeList: async(data) => {
        return await request({
            url: '/disease-type/list/type',
            method: 'POST',
            data
        })
    },
    getCustomFieldList: async(data) => {
        return await request({
            url: '/project-custom/findAllCustom',
            method: 'POST',
            data
        })
    },
    // 查询病害列表
    getDiseaseDataList: async(data) => {
        return await request({
            url: '/inspection-report/find/report/disease/data',
            method: 'POST',
            data
        })
    },
    // #endregion 巡检报告配置项相关
    // 巡检图片上传前先提交本次要上传的文件信息
    inspectionUploadBefore(data) {
        return request({
            url: '/images-record/adds',
            method: 'POST',
            data
        })
    },
    // 创建巡检图片分组
    createInspectionGroup(data) {
        return request({
            url: '/inspection-image-group',
            method: 'POST',
            data
        })
    },
    // 删除巡检图片分组
    deleteInspectionGroup(id) {
        return request({
            url: `/inspection-image-group/${id}`,
            method: 'DELETE'
        })
    },
    // 修改巡检图片分组
    updateInspectionGroup(data) {
        return request({
            url: '/inspection-image-group',
            method: 'PUT',
            data
        })
    },
    // 标记收藏
    markCollection(data) {
        return request({
            url: '/inspection-disease/star',
            method: 'PUT',
            data
        })
    },
    // 取消收藏
    cancelCollection(data) {
        return request({
            url: '/inspection-disease/star-cancel',
            method: 'PUT',
            data
        })
    },
    // 图片分组
    getInsImgGroup(inspectionId) {
        return request({
            url: '/inspection-image-group/tree',
            method: 'GET',
            params: { inspectionId }
        })
    },
    // 查询巡检图片
    getInsImgsByFilter(data) {
        return request({
            url: '/inspection-image/page/img',
            method: 'POST',
            data
        })
    },
    // 批量删除巡检图片
    deleteInsImgs(data) {
        return request({
            url: '/inspection-image/batch',
            method: 'DELETE',
            data
        })
    },
    updatePartSort(data) {
        return request({
            url: '/space-marking/update/sort',
            method: 'POST',
            data
        })
    }

}