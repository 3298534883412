
export default {
  showList: 'Show List ',
  hideList: 'Hide List',
  diseaseList: 'Defect List',
  diseaseType: 'Defect Type',
  marker: 'Marker',
  action: 'Operate',
  deleteDiseaseTips: 'Are you sure to delete this defect?',
  diseaseDraw: 'Add disease',
  editDisease: 'Edit disease',
  diseaseSize: 'Size(L:m；W:mm；S:㎡)',
  diseaseDes: 'Description',
  highTips: 'L:Long',
  widthTips: 'W:Width',
  areaTips: 'S:Area',
  diseaseTips: 'Enter defect description',
  noDiseaseTips: 'Currently,there is no defect.',
  noImagesTips: 'No image is attached to the current inspection point',
  checkMode: 'Inspect model',
  viewMode: 'View model',
  viewModeTips: 'In View mode,the screening status of the images will not change.',
  checkModeTips: 'In Inspect Mode, when switching images, the previous image will be marked as inspected.',
  setProblem:'Set to doubtful',
  cancelProblem: 'Cancel doubt',
  beforeError: "It's already the first one!",
  nextError: "It's already the last one!",
  reDrawTips: 'After the redrawing mark is finished, the disease mark is automatically saved and the component component is recalculated to decide whether to continue',
  struct: 'Component',
  autoSave: 'Autosave...',
  saveDiseaseTitle:'Whether to save disease information',
  saveDiseaseTips:'You will jump to the space tag page to add a tag, whether the disease information of the current picture tag is saved？',
  confirmSaveButton: 'Save and jump',
  cancelSaveButton: 'Skip without saving',
  screenStatusUpdateSuccess:'The screening status update was successful',
  batchdeleteDiseaseTips:'Are you sure to batch delete the diseases you selected?'
}
