
export default {
  singleFileLimitTips: '单文件不能超出100MB',
  parseExifError: '图片属性解析失败',
  presignError: '图片预上传路径获取失败',
  parseXMPError: '图片XMP属性解析失败',
  fileUploadError: '文件上传失败',
  thumbnailError: '图片缩略图获取失败',
  cancelUploadTips: '操作还未完成，是否取消？',
  inspectionImageUploading: '巡检图片上传中',
  cancelUpload: '取消',
  uploadFailureTips: '上传失败，请检查网络稍后重试',
  donwloadFailureTips: '下载失败，请检查网络稍后重试',
  inspectionImageUploadComplete: '巡检图片上传完成',
  filesUploadTipsStepOne: '只允许上传',
  filesUploadTipsStepTwo: '类型的文件！当前文件类型为',
  fileTypeError: '文件类型错误',
  singleFileTipsStepOne: '单个文件最大不超过',
  singleFileTipsStepTwo: 'MB！当前文件大小为',
  fileLimitError: '文件大小超出限制',
  uploadFail: '上传失败',
  continueUpload: '继续',
  fileReadingText:'文件读取中,请稍等...'
}
