import RouterMangerApi from './module/RouterMangerApi'
import UserMangerApi from './module/UserMangerApi'
import ProjectAPI from './module/ProjectMangerApi'
import InviteMangerApi from './module/InviteMangerApi'
import InspectionDetailApi from './module/InspectionDetailApi'
import NoticeApi from './module/NoticeApi'
import SysToolsAPI from './module/SysToolsApi'
import DictionaryAPI from './module/DictionaryApi'
import SpatialMarker from './module/SpatialMarker'
import RecycleBinApi from './module/RecycleBinApi'
import TeamApi from './module/TeamApi'
import InspectionMangerApi from './module/InspectionMangerApi'

export {
  RouterMangerApi,
  UserMangerApi,
  ProjectAPI,
  InviteMangerApi,
  InspectionDetailApi,
  SysToolsAPI,
  NoticeApi,
  DictionaryAPI,
  SpatialMarker,
  RecycleBinApi,
  TeamApi,
  InspectionMangerApi

}
