export default {
  spatialMarker: 'Spatial Marker',
  searchPlaceholder: 'Search Spatial Marker Name',
  autoBind: 'Auto Bind Inspection Photos',
  createGroup: 'Create Group',
  createChild: 'Create Child Group',
  createSpacialMarker: 'Create Spatial Marker',
  groupType: 'Group Type',
  groupName: 'Group Name',
  childGroupName: 'Child Group Name',
  groupTypePlaceholder: 'Please select group type',
  groupNamePlaceholder: 'Please enter group name',
  groupNameRequired: 'Group Name is required',
  groupNameDuplicate: 'Group Name cannot be duplicated',
  childGroupNameRequired: 'Please enter child group name',
  markerColorPlaceholder: 'Please select marker color',
  markerColor: 'Select marker color',
  editGroup: 'Edit Group',
  deleteSpatialMarker: 'Delete Spatial Marker',
  deleteGroup: 'Deleting a group also deletes the contents in the group. Do you want to continue?',
  autoBindTitle: 'Select the range for automatically binding photos',
  distanceLabel: 'Set the distance to bind the photo (unit :cm)',
  distancePlaceholder: 'Please enter a value ranging from 10 to 200',
  unBind: 'not bound',
  bind: 'Bound',
  startCount: 'begin to calculate',
  batchRemove: 'Batch remove photos',
  confirmRemove: 'Remove',
  selecteAll: 'Select all',
  removeSuccess: 'Remove Success',
  removeSingle: 'Are you sure you want to remove the current photo?',
  removeSelect: 'Batch remove will remove the photos you have selected. Do you want to continue?',
  editSuccess: 'Edit Success',
  addSuccess: 'Add Success',
  addSpaceMarker: 'Add Spatial Marker',
  editSpaceMarker: 'Edit Spatial Marker',
  markerNumber: 'Number',
  numberPlaceholder: 'Please enter the number example:1#',
  markerName: 'Part/Component Structure Name',
  markerPosition: 'Spatial Marker Position',
  picked: 'selected',
  unPicked: 'unselected',
  choose: 'Choose',
  reChoose: 'Re-choose',
  imageManagement: 'Image Management',
  historyList: 'History Inspection Photo List',
  compare: 'Compare Photos',
  disease: 'Disease',
  nameView: 'Marker Name Preview',
  partNamePlaceholder: 'Please enter name',
  markerPlaceholder: 'Please mark on the model',
  startCompare: 'Start Compare',
  hide: 'Hide',
  open: 'Open',
  rePickMarkerTips: 'Are you sure you want to delete the spatial marker?',
  onlyOneInspection: 'Each inspection can only select one photo to compare',
  onlyFourInspection: 'Each comparison can only support four photos',
  structure: 'Structure',
  other: 'Other',
  computeSuccess: 'Auto Bind Inspection Photos Success',
  moveToGroup: 'Bind to Spatial Marker',
  moveToTitle: 'Please select the marker you want to move to',
  moveSuccess: 'Move Success',
  moveOutSuccess: 'Remove Success',
  noInspectionData: 'No Inspection Data',
  hideMarker: 'Hide Spatial Marker',
  showMarker: 'Show Spatial Marker',
  editMarkerName: 'Edit Marker Name',
  unbindImg: 'Pending Binding Photo Number',
  unbindImgPlaceholder: 'Pending Binding Part/Component Photo Number',
  addImg: 'Add photo',
  showAllImage: 'Show all photos',
  currentInspection: 'Current inspection',
  addNewMarker: 'Add',
  rectSelect: 'Box select',
  disableRectSelect: 'Cancel box select',
  rectTips: 'Click the left mouse button to select a box',
  compareTips: 'Select 2 to 4 photos for comparison. Select a maximum of one photo per inspection.',
  insNum: 'Inspection Number',
  imgNum: 'Photo Number',
  noEnoughDataTips: 'Photos are needed for comparison from at least two inspections',
  noDataTips: 'No inspection data is currently available, so photos cannot be compared.'
}
