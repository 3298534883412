export default {
  imagePoint: '图片点',
  inspectionPoint: '巡检点',
  diseasePoint: '病害',
  perspective: '透视',
  diseaseDisplay: '病害显示模式',
  point:'点',
  line:'形状',
  shapeTips: '切换病害为[形状]展示时,性能损耗加大',
  checkedImages:'已选图片',
  searchPlaceholder: '搜索部件构件',
  'no-bing-img':'未绑定部件构件',
  structure:'部件构件',
  other:'普通标记',
  spatialMaker:'空间标记',
  structureCounts: '构件',
  imageCounts:'图片',
  markerCounts:'标记'
}
