export default {
  accountLogin: '账号登录',
  wxLogin: '微信登录',
  phoneNumber: '手机号',
  phoneNumberPlaceholder: '请输入手机号',
  phoneNumberFormatError: '请输入正确的手机号',
  password: '密码',
  passwordPlaceholder: '请输入密码',
  forgetPassword: '忘记密码',
  clickButtonStartVerify: '点击按钮开始验证',
  verifySuccess: '验证成功',
  placeVerify: '请按照提示完成验证',
  login: '登录',
  logging: '登录中',
  unregistered: '还未注册',
  toRegister: '去注册',
  verifyBoxTitle: '请完成安全验证',
  loginSuccess: '登录成功',
  findPassword: '找回密码',
  SMSVerificationCode: '短信验证码',
  SMSVerificationCodePlaceholder: '请输入短信验证码',
  getVerifyCode: '获取验证码',
  resend: '重新发送',
  resetPassword: '重设密码',
  setNewPassword: '设置新密码',
  repassword: '再次输入密码',
  repasswordPlaceholder: '请再次输入密码',
  passwordInconsistency: '两次输入密码不一致',
  passwordFormatTips: '密码必须包含字母和数字，且长度为8-20位',
  resetPasswordSuccess: '重设密码成功',
  haveAccount: '已有账号',
  toLogin: '去登录',
  setPassword: '设置密码',
  completeInfomation: '完善注册信息',
  name: '姓名',
  namePlaceholder: '请输入姓名',
  companyName: '你的单位/学校名称',
  companyNamePlaceholder: '请输入你的单位或学校名称',
  job: '你的岗位（职业）',
  jobPlaceholder: '请输入你的岗位',
  agree: '我已阅读并同意',
  licenseAgreement: '许可协议',
  privacyAgreement: '隐私协议',
  register: '注册',
  checkUserAgreement: '请先勾选用户协议',
  registerSuccess: '注册成功',
  sliderVerifyTips: '向右滑动完成验证',
  verifyFail: '验证失败',
  'repeatRegister': '该手机号已注册，请直接登录',
  loginTitle: '登录通途检',
  registerTitle: '注册通途检账号',
  broswerTipsText:'当前浏览器存在兼容性问题，系统部分功能可能无法正常使用。建议使用以下浏览器访问。',
  googleChrome:'谷歌浏览器',
  edge:'Edge'
}
