import { defineStore } from 'pinia'
import { ProjectAPI } from '@/api'

export const teamStore = defineStore('team', {
  state: () => {
    return {
      teamId: '',
      teamList: null,
      teamName: ''
    }
  },
  actions: {
    resetTeam() {
      this.teamId = ''
      this.teamName = ''
      this.teamList = null
    },
    setTeamId(id) {
      this.teamId = id
    },
    setTeamName(teamName) {
      this.teamName = teamName
    },
    async getTeamList() {
      const teamList = await ProjectAPI.getAllTeams()
      this.teamList = teamList
    }
  }
})
