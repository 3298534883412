
export default {
  recycleBin: '数据回收站',
  subtitle: '支持数据还原，你也可彻底删除',
  restoreAll: '批量还原',
  completelyDeleteAll: '批量彻底删除',
  cleanRecycleBin: '清空回收站',
  searchPlaceholder: '搜索数据',
  filterType: '筛选类型',
  serialNumber: '序号',
  dataName: '数据名称',
  dataType: '类型',
  fileSize: '文件大小',
  operator: '操作人',
  deleteDate: '删除时间',
  operation: '操作',
  stored: '回收站占用空间',
  completelyDelete: '彻底删除',
  completeDeletePlaceholder: '彻底删除该条数据吗？将不可恢复。',
  completeDeleteAllPlaceholder: '彻底删除需输入登录密码验证，确认删除数据将永久被删除不可恢复。是否彻底删除？',
  restore: '还原',
  restoreSuccess: '还原成功',
  deleteSuccess: '删除成功',
  cleanScuess: '清空成功',
  pleaseSelect: '至少选择一项',
  dataNameTips: '该项目数据包含项目基本信息、项目模型、巡检信息、病害信息和巡检报告。',
  emptyCycleBinPlaceholder: '清空回收站需输入登录密码验证，确认删除数据将永久被删除不可恢复。是否清空回收站？'
}
