const draggable = {
  mounted(el) {
    const header = el.childNodes[0]
    header.style.cursor = 'move'
    header.onmousedown = function(e) {
      var disx = e.pageX - el.offsetLeft
      var disy = e.pageY - el.offsetTop
      header.style.cursor = 'move'
      document.onmousemove = function(e) {
        if (e.pageY > 50 && e.pageY < window.innerHeight - 50 && e.pageX > 150 && e.pageX < window.innerWidth - 150) {
          el.style.left = e.pageX - disx + 'px'
          el.style.top = e.pageY - disy + 'px'
        }
      }
      document.onmouseup = function() {
        document.onmousemove = document.onmouseup = null
        header.style.cursor = 'default'
      }
    }
  }
}

export default draggable
