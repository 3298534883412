
export default {
  singleFileLimitTips: 'A single file cannot exceed 80 MB.',
  parseExifError: 'Failed to parse image properties',
  presignError: 'Failed to retrieve the pre-upload path for the image.',
  parseXMPError: 'Failed to parse image XMP properties',
  fileUploadError: 'File upload failed',
  thumbnailError: 'Failed to retrieve image thumbnail',
  cancelUploadTips: 'If the operation is not complete, cancel it or not?',
  inspectionImageUploading: 'Inspection images are being uploaded.',
  cancelUpload: 'Cancel',
  uploadFailureTips: 'Upload failed. Please check your network connection and try again later',
  downloadFailureTips: 'Download failed. Please check your network connection and try again later',
  inspectionImageUploadComplete: 'Inspection images upload completed',
  filesUploadTipsStepOne: 'Uploads only',
  filesUploadTipsStepTwo: 'File type! Current file type is',
  fileTypeError: 'File type error',
  singleFileTipsStepOne: 'The maximum size for a single file is',
  singleFileTipsStepTwo: 'MB! Current file size is',
  fileLimitError: 'File size exceeds the limit',
  uploadFail: 'Upload failed',
  continueUpload: 'Continue',
  fileReadingText: 'File reading, please wait... '
}
