import { ElMessage } from 'element-plus'
import _ from 'lodash'
export const MsgType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
}
export const setMsg = _.debounce((message, type = 'info', options = {}) => {
  if (window.msgObj) {
    window.msgObj.close()
    window.msgObj = null
  }
  if (!message) {
    return
  }
  window.msgObj = ElMessage({
    message,
    type,
    ...options
  })
}, 100)
