import { defineStore } from 'pinia'
export const userInfoStore = defineStore('userInfoStore', {
  state: () => {
    return {
      userInfo: null
    }
  },
  actions: {
    setUserInfo(userInfo) {
      console.trace(userInfo)
      this.userInfo = userInfo
    },
  }
})
