export default {
  diseaseTypeAndFilterRange: 'Disease types and filtering range screened by AI',
  editDiseaseFilterRange: 'Modify the disease filtering range',
  filterRangeEmpty: 'The filter range is not set',
  screeningComplete: 'AI screening completed',
  totalTime: 'Total Time',
  reflush: 'Manual refresh',
  allInspectionPhoto: 'To be screened by AI',
  allInspectionPhotoTips: 'Manually screened photos are not included',
  screenedPhoto: 'Screened photograph',
  tobeReviewedPhoto: 'To be reviewed photo',
  AIDiseaseTotal: 'Total AI diseases',
  diseasePhoto: 'Disease photograph',
  diseaseMarkNum: 'Total number of disease markers',
  diseaseFilterNum: 'filtered quantity',
  diseaseNum: 'Disease quantity',
  all: 'All',
  tobeReviewed: 'To be reviewed',
  reviewed: 'Reviewed',
  waitScreened: 'To be screened',
  noDisease: 'disease-free',
  screendAbnormal: 'AI screening for anomalies',
  batchReview: 'Batch review',
  unscreened: 'unscreened',
  confirmReview: 'Confirmation review',
  confirmReviewText: 'Do you confirm the review?',
  stopScreening: 'Stop screening',
  startScreening: 'Start screening',
  queueUp: 'AI screening in line',
  queueUpRate: 'The current row contains the {num} bit',
  jumpQueue: 'Jump a queue',
  queueCancel: 'unqueue',
  screeningCompleteText:
    'AI screening is complete, please check the disease pictures reviewed',
  filterRangeTitle: 'Adjust the disease filtering range',
  filterRangeTips:
    'Tip: Please set the filter range. Diseases smaller than the set range will not be displayed.',
  diseaseFilterConfigTitle:
    'The types of diseases screened by AI',
  picRateTitle: 'The range of images screened by AI',
  picRangeTitle: 'Select a range of images for AI screening',
  diseaseTypeTitle: 'Check the disease type screened by AI',
  adjustFilterRange: 'Adjust filter range',
  diseaseCrackSizeText:
    'Filter diseases with length <{length}m, or width <{width}mm',
  diseaseOtherSizeText: 'Filter diseases with area <{area}m² ',
  batchReviewText: 'Batch review will update the status of all the pictures you selected to reviewed, do you want to continue?',
  picRateRadio1: 'All inspection images (do not delete existing manually marked diseases) ',
  picRateRadio2: 'All inspection images (remove existing manually marked diseases) ',
  picRateRadio3: 'Only unscreened inspection images ',
  stopScreeningText: 'AI screening has not been completed, should we stop screening?',
  stopScreeningLoadingText: 'AI screening stopped...',

  feedbackText: 'Misidentification feedback',
  feedbackSubText: 'You can feedback on the problem of AI misidentifying diseases here',
  feedbackPlaceholder: 'Please enter the problem that AI misidentifies disease',
  remainingTime: 'Estimated remaining time',
  selectPicRate: 'Select a range of images for AI screening',
  setBindingRange: 'Set binding range',
  setBindingRangeSubText: 'Scope threshold of component binding disease (unit :m)',
  setBindingRangeTips: 'When a disease binds a component component, only the component component in this range is bound.',
  setBindingRangeEmpty: 'The disease range cannot be empty',
  AIDisable: 'AI unavailable',
  waitScreenedTitle: 'Images that have not been screened by AI\' and whose screening status is\' unscreened \'or\' doubtful ',
  tobeReviewedTitle: 'Images that have been \"screened by AI\" and whose review status is \"pending Review\"',
  reviewedTitle: 'Images whose status is reviewed ',
  inspectionImgDeleteTips: 'Confirm that you want to delete this inspection image?',
  bindSpaceMark: 'Bound to',
  directRunScreening: 'AI screening',
  noSpaceMarkTips: 'There are still inspection photos with unbound space markers. Do you want to continue AI screening?',
  moreSpaceMarkerTips: 'Multiple component components are bound',
  NOT_EXISTING: 'AI screening ',
  AI_CHECKING: 'AI screening ',
  AI_QUEUE: 'AI screening in queue ',
  AI_CHECK_EXCEPTION: 'AI screens for exceptions ',
  AI_SERVER_EXCEPTION: 'AI server exception ',
  AI_CHECK_COMPLETE: 'AI screening completed ',
  AI_In_Preparation: 'AI in preparation',
  TASK_CANCELING: 'AI Canceling',

  NO_INSPECTION_FOR_THE_IMAGE: 'Inspection information on the image cannot be queried ',
  PROJECT_MODEL_ERROR_FOR_THE_IMAGE: 'The project model of the image is not queried or the project model center point is incomplete ',
  IMAGE_ERROR_FOR_THE_DISEASE: 'The picture information of the disease is not found or the picture information is incomplete ',
  IMAGE_PX_LENGTH_CALCULATION_ERROR: 'Image pixel length calculation error ',
  DISEASE_TYPE_NOT_COVER_AI: 'disease type does not cover AI screening result type ',
  DISEASE_IMAGE_URL_NULL: 'disease picture URL is empty ',
  AI_RESULT_SAVE_ERROR: 'AI result saving exception ',
  TWO_D_TO_3D_FAIL: ' Abnormal transition from 2D to 3D coordinates ',
  AI_SERVER_CHECK_ERROR: 'AI service check_error ',

  errorTips: 'AI service exception',
  diseaseFiltration: 'Disease filtration',


}
