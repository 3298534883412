import service from '@/components/Verifition/src/utils/axios'

export default {
  goBack: '返回',
  minimization: '最小化',
  pointHeader: '巡检点位管理',
  add: '新增',
  import: '导入',
  photoCounts: '照片数',
  chooseOneData: '请至少选择一条巡检数据',
  editPoint: '编辑巡检点位',
  inspectionPointLocation: '巡检点位置',
  pointSelected: '已上传',
  Uploaded: '已上传完成',
  reSelect: '重选',
  imageManage: '图片管理',
  importCameraType: '导入相机型号',
  uploadFailList: '上传失败列表',
  removeImageTips: '将此图片从该巡检点移除?',
  moveToOtherPoint: '移动到其他巡检点',
  drawPointTips: '请通过鼠标[左键]标记空间点位置',
  deleteInspection: '删除巡检',
  deleteInspectionTips: '你正在删除 {name} 巡检，请输入登录密码验证，点击删除该巡检将被移入回收站。',
  inspectionPhoto: '巡检照片',
  inspectionPoint: '巡检点位',
  createInspection: '创建巡检',
  searchProjectName: '搜索项目名称',
  startScreening: '筛查',
  projectNoInspection: '该项目未创建巡检',
  editInspection: '编辑巡检',
  viewReport: '查看报告',
  pointManage: '巡检点位管理',
  inspectionMsg: '巡检信息',
  inspectionImage: '巡检图片',
  inspectionName: '巡检名称',
  inspectionNamePlaceholder: '请输入巡检名称',
  inspectionDescription: '巡检描述',
  inspectionDescriptionPlaceholder: '请输入巡检描述',
  InspectionNoPoint: '该巡检未创建巡检点',
  createPoint: '创建巡检点',
  inspectionList: '巡检列表',
  computeImages: '巡检点自动绑定图片',
  computeImagesTips: '自动将所有图片移入最近的巡检点',
  docPreView: '报告预览',
  reportDownload: '下载{name}报告',
  addPoint: '新增巡检点位',
  deleteImageTips: '确定删除该张图片吗?',
  pointNoImages: '该巡检点暂无图片',
  insDocList: '巡检报告列表',
  docName: '报告名称',
  docStatus: '报告状态',
  docUser: '责任人',
  docCreateTime: '生成时间',
  docDelete: '删除报告',
  docNewest: '最新报告',
  docHistory: '历史报告',
  docFail: '生成失败',
  docCreating: '生成中',
  docStatusTips: '最新报告：该巡检最新状态的报告；<br />历史报告：过去生成的报告或有病害等数据更新的新报告生成。',
  docDeleteTips: '确认删除该巡检报告吗？',
  inspectionReport: '巡检报告',
  diseaseSearchPlaceholder: '搜索病害编号',
  diseaseType: '病害类型',
  diseaseTypePlaceholder: '请选择病害类型',
  diseaseScalePlaceholder: '请选择标度',
  diseaseExport: '导出病害',
  diseaseSize: '病害尺寸',
  diseaseEdit: '编辑病害',
  marker: '标记人',
  updater: '更新人',
  diseaseDeleteTips: '确定删除该病害的全部信息吗？',
  diseaseList: '病害列表',
  diseaseDescPlaceholder: '请输入病害描述',
  time: '时间',
  length: '长度',
  width: '宽度',
  area: '面积',
  noImagesTips: '当前暂无可绑定的图片',
  disease: '病害',
  total: '共',
  tiao: '条',
  allDisease: '全部病害',
  hideList: '列表隐藏',
  showProjectList: '展开项目列表',
  hideProjectList: '隐藏项目列表',
  addImageItem: '添加一组',
  imageUploadTips: '上传提示：相机型号或拍摄距离不同的照片需分组上传，且单张图片的大小不超图100MB',
  cameraType: '相机型号',
  cameraTypePlaceholder: '请选择相机型号',
  photoDistance: '拍照距离（米）',
  photoDistancePlaceholder: '请输入拍照距离',
  photoDistancePattern: '请输入大于0的整数',
  sheetNum: '张',
  inspectionDelTips: '确认删除该组巡检图片信息吗?',
  imageUploadPlaceholder: '请选择要上传的巡检图片',
  createDoc: '生成报告',
  pixelVerify: '当前选择【{model}】相机的分辨率({resolvingPowerLong}*{resolvingPowerWide})与上传图片的分辨率({PixelXDimension}*{PixelYDimension})不一致，继续上传会导致病害尺寸和位置异常，是否继续？',
  noDisease: '该巡检暂无病害',
  editSubmitEmpty: '请添加巡检图片',
  selectRepeatDir: '当前选择的文件夹【{name}】与上次上传的文件夹相同，是否继续?',
  noReport: '无报告',
  chooseImageTips: '选择图片文件夹',
  AIScreening: 'AI筛查',
  reportDiseaseTitle: '报告病害表格自定义配置',
  selectTableFieldSort: '选择表格字段并排序',
  tablePreview: '表格预览效果',
  reportDiseaseOutLimit: '报告病害表格字段最多勾选8个',
  exportDiseaseRange: '病害导出范围',
  exportDoubtfuldata: '导出存疑数据',
  exportTobeReviewedData: '导出待复查数据',
  AIDisable: 'AI不可用',
  startGenerate: '开始生成',
  allPhoto: '全部照片',
  notScreeningPhoto: '未筛查照片',
  impeachPhoto: '存疑照片',
  reportCreateSuccessTitle: '巡检报告生成成功',
  reportCreateSuccessText: '巡检报告生成成功,请查看最新报告',
  reportCreateFailTitle: '巡检报告生成失败',
  reportCreateFailText: '巡检报告生成失败,请重新生成报告',
  currentInspectionDelete: '当前巡检被删除',
  diseaseBatchDeleteConfirmText: '确认删除选中的病害数据吗？',
  uploadImageEmpty: '还未上传任何巡检图片,请先',
  docConfigTitle: '生成报告自定义配置',
  filterDiseaseTasks: '筛选病害范围',
  openFilter: '展开',
  foldFilter: '收起',
  partName: '部件构件',
  partNamePlaceholder: '共计部件构件数',
  partNameCheckedPlaceholder: '，已选',
  pickPart: '去选择',
  unit: '个',
  all: '全部',
  batchEdit: '批量修改',
  sortTips: '上下拖动排序',
  totalSummary: '共计',
  unBindPart: '未绑定部件构件',
  diseaseNumSet: '设置病害展示数量',
  diseaseNumPlaceholder: '提示：请设置同一构件上相同类型的病害展示数量，可按病害面积或长或宽的top值来设定',
  selfDefined: '自定义病害类型',
  filterResult: '病害筛选结果',
  filterResultSum: '共计病害数',
  favDisease: '收藏夹',
  redoTips: '撤销移出',
  doSearchTips: '每次查询会更新病害筛选的结果',
  faved: '已收藏',
  notFaved: '未收藏',
  removeTip: '移出',
  favPanelTitle: '添加到筛选结果',
  allSelect: '全选',
  noFavDisease: '收藏夹暂无数据',
  addImageGroup: '添加分组',
  editImageGroup: '修改分组',
  groupName: '分组名称',
  groupNameExists: '分组名已存在',
  groupNameLengthError: '分组名长度不能超过10个字符',
  selectImageFold: '选择文件夹',
  addImageFold: '添加文件夹',
  selectImageFoldTips: '选择图片文件夹',
  uploadStatus: '上传状态',
  autoComputed: '自动计算',
  placeAddImage: '请选择巡检图片',
  imageUploadBreakTips: '未全部上传完成，请选择原文件夹续传',
  imageUploadBreakReselectTips: '文件已选择，点击提交开始续传',
  imageUploadDirErrorTips: '当前选择的文件夹【{name}】与上次上传的文件夹不同，是否继续?',
  zoomRatio: '变焦倍数',
  noModel: '无模型',
  computedValue: '计算值',
  computeDistanceLoadingText: '距离计算中，点击[此处]取消计算',
  groupDeleteError: '请先删除分组下的图片',
  'no-bind-marking': '未绑定部件构件',
  click2Fav: '点击收藏',
  favStatues: '收藏状态',
  docReReport: '重新生成',
  imgStatus: '图片状态',
  imgGroups: '图片分组',
  imgName: '图片名称',
  imgNamePlaceholder: '请输入图片名称',
  uploadTime: '上传时间',
  batchDownload: '批量下载',
  batchDelete: '批量删除',
  unscreened: '未筛查',
  reviewed: '已筛查',
  doubt: '存疑',
  batchDeleteTips: '是否确认删除选中的巡检图片？',
  downImg: '下载图片',
  downDiseaseImg: '下载病害图片',
  diseaseNumLimit: '病害数量超过5000',
  choose: '选择',
  sort: '排序',
  hasDisease: '有病害',
  hasNotDisease: '无病害',
  diseaseSizeTips: '按照病害面积或长或宽的top值来设定',
  unitTips: 'S=面积，L=长度，W=宽度',
  L: '长度',
  W: '宽度',
  S: '面积',
  diseaseImgView: '查看详情',
  serviceError: '服务异常',
  filterImg: '筛选图片',
  collectionSuccess: '收藏成功',
  collectionCancel: '取消收藏成功',
  batchData: '批量删除',
  batchDataTips: '确定批量删除勾选的巡检图片吗？请输入登录密码验证，确认删除数据将永久被删除。',
  dataLoading: '数据加载中',
  noMoreData: '没有更多数据了'
}
