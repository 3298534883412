export default {
  teamManagement: 'Team management',
  memberManagement: 'Member management',
  teamSetup: 'Team Settings',
  people: 'members',
  InviteMember: 'Invite Member',
  memberCountTips: 'Current members: {currentNum}, maximum allowed: {totalNum}',
  searchMember: 'Search members',
  userName: 'User name',
  roleName: 'Role',
  lastLoginTime: 'Last Login Time',
  inviter: 'Inviter',
  operation: 'Action',
  moveOutTeam: 'Remove from Team',
  batchMoveOutTeam: 'Mass Removal',
  screeningRole: 'Filter by Role',
  modifyRole: 'Modify role',
  adjustment: 'Project Access Permissions',
  moveAllout: 'Remove All',
  batchMoveAlloutTips: 'After removing members, the content created by those members will still be retained.Do you want to remove all the selected members?',
  moveoutTips: 'After removing a member, the content created by that member will still be retained, do you want to remove this member?',
  updateTeamRole: 'Modify team roles',
  selectedMember: 'Selected member',
  roleDescription: 'Role description',
  adminDescription: 'Administrator: Manage projects and team members.',
  inspectorDescription: 'Inspector: View, tag, and edit projects and defects.',
  visitorDescription: 'Viewer: View projects and defects only.',
  updateProjectAuth: 'Adjust Project Participation Permissions',
  searchProjectName: 'Search Project Name',
  allProject: 'All projects',
  selectedProject: 'Selected projects',
  inviteMember: 'Invite members',
  selectProjectRole: 'Selecting project permission',
  selectProjectRoleSub: ' Invite to join this team and assign project permissions.',
  onlyJoinTeam: 'Join the team without project permissions.',
  joinTeamProject: 'Join the team with limited project permissions.',
  selectRole: 'Select role',
  selectRoleSub: 'External members will have which role after joining the team?',
  inviteTobe: 'Invite as',
  setPassword: 'Set Link Password',
  randomPassword: 'Generate Random Password ',
  customPassword: 'Customize Password',
  linkValidity: 'Expire Time',
  createLink: 'Generate link',
  onlyCopyLink: 'Copy Link Only',
  copyLinkAndPassword: 'Copy All',
  teamFullTips: 'The team is at full capacity. Please contact the sales manager for a software upgrade.',
  inviteLink: 'Invitation link',
  linkPassword: 'Link password',
  expired: ' Expired ',
  effective: 'Active',
  copySuccess: 'Copy Successful',
  copyFail: 'Copy failed, link is invalid, please regenerate',
  teamName: 'Team name',
  handover: 'Transfer Team',
  handoverTips: 'Each team can only have one owner. Please contact technical support to transfer the team.',
  updateTeamName: 'Change Team Name',
  teamNamePlaceholder: 'Please enter the team name',
  joinTeam: 'Join Team',
  projectType: 'Project type management',
  deleteProjectTypeTips: 'Are you sure to delete the project type? The project type will be deleted, and the corresponding projects will be deleted as well.',
  uploadExceedVolume: 'Team storage capacity is up, please upgrade',
  uploadBeAboutToVolumn: 'Team storage capacity is about to reach its limit, please upgrade',
  membershipFunction: 'Membership function',
  membershipFunctionTitle: 'Member feature function opening status',
  membershipFunctionName: 'Function name ',
  membershipFunctionDesc: 'Function Description ',
  membershipFunctionEffectiveness: 'effectiveness',
  membershipFunctionExpirationTime: 'maturity',
  membershipFunctionRenew: 'Membership function renewal',
  renewDialogTitle:' Member Feature Renewal ',
  renewDialogSubtitle:' Member special features to renew, please contact your regional sales manager ',
  notOpen:'Not open',
  expired:'Expired',


}
