import request from '../axios.js'

export default {
  // 查询团队下的人员列表
  getUsers: (params) => {
    return request({
      url: '/team-client/user-page',
      method: 'GET',
      params
    })
  },

  // 将人员移除团队
  removeUser: (data) => {
    return request({
      url: '/team-client/remove-user',
      method: 'PUT',
      data
    })
  },

  // 获取团队下的角色
  getRoles: () => {
    return request({
      url: '/role/list/team',
      method: 'GET'
    })
  },
  getAllRoles: () => {
    return request({
      url: '/role/list/team-all',
      method: 'GET'
    })
  },
  // 获取团队详情
  getTeamInfo: () => {
    return request({
      url: `/team-client/details`,
      method: 'GET'
    })
  },
  // 修改成员角色
  updateUserRole: (data) => {
    return request({
      url: '/team-client/user-role',
      method: 'PUT',
      data
    })
  },
  getAllProject: (data) => {
    return request({
      url: '/project/findTeamAll',
      method: 'POST',
      data
    })
  },
  // 获取当前登录人的项目
  getUserProject: (userId) => {
    return request({
      url: `/project-belong/findProjectByUser/${userId}`,
      method: 'GET'
    })
  },
  // 查询团队的邀请链接
  queryInvitationLink: () => {
    return request({
      url: '/team-client/invitation-link',
      method: 'GET'
    })
  },
  // 生成邀请链接
  createInviteLink: (data) => {
    return request({
      url: '/team-client/invitation-link',
      method: 'POST',
      data
    })
  },
  // 设置用户项目
  setUserProject: (data) => {
    return request({
      url: '/project-belong/bingUserToProjectS',
      method: 'POST',
      data
    })
  },
  // 更新团队信息
  updateTeamInfo: data => {
    return request({
      url: '/team-client',
      method: 'PUT',
      data
    })
  },
  teamMemberFunction:(params)=>{
    return request({
      url:'/team-client/function',
      method:'GET',
      params
    })
  }
}
