import request from '../axios.js'

// 获取系统提示卡片消息
export const getSystemTipcardMessage = () => {
  return request({
    url: '/notice/tip-card',
    method: 'GET'
  })
}
export default {
  // 设置消息已读
  feedback: async(data) => {
    const res = await request({
      url: `/feedback`,
      method: 'POST',
      data
    })
    return res
  }

}
