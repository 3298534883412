
export default {
  showList: '展开',
  hideList: '隐藏',
  diseaseList: '病害列表',
  diseaseType: '病害类型',
  marker: '标记人',
  action: '操作',
  deleteDiseaseTips: '确定要删除该病害吗?',
  diseaseDraw: '新增病害',
  editDisease: '编辑病害',
  diseaseSize: '病害尺寸(L:m；W:mm；S:㎡)',
  diseaseDes: '病害描述',
  highTips: 'L:长度',
  widthTips: 'W:宽度',
  areaTips: 'S:面积',
  diseaseTips: '请输入病害描述',
  noDiseaseTips: '当前暂无病害',
  noImagesTips: '当前巡检点暂未绑定图片',
  checkMode: '筛查模式',
  viewMode: '查看模式',
  viewModeTips: '查看模式下,图片的筛查状态不会变化',
  checkModeTips: '筛查模式下,切换图片,上一张图片将更新为已筛查',
  setProblem:'设为存疑',
  cancelProblem: '取消存疑',
  beforeError: '已经是第一张了!',
  nextError: '已经是最后一张了!',
  reDrawTips: '重绘标记结束后会自动保存病害标记以及重新计算部件构件,是否继续',
  struct: '部件构件',
  autoSave: '自动保存中...',
  saveDiseaseTitle:'是否保存病害信息',
  saveDiseaseTips:'你将跳转至空间标记页面新增标记，当前图片标记的病害信息是否保存？',
  confirmSaveButton: '保存并跳转',
  cancelSaveButton: '不保存跳转',
  screenStatusUpdateSuccess:'筛查状态更新成功',
  batchdeleteDiseaseTips:'确定批量删除你框选的病害吗？'
}
