export default {
  teamManagement: '团队管理',
  memberManagement: '成员管理',
  teamSetup: '团队设置',
  people: '人',
  InviteMember: '邀请成员',
  memberCountTips: '当前成员{currentNum}人，最多{totalNum}人',
  searchMember: '搜索成员',
  userName: '姓名',
  roleName: '角色',
  lastLoginTime: '最后登录时间',
  inviter: '邀请人',
  operation: '操作',
  moveOutTeam: '移出团队',
  batchMoveOutTeam: '批量移出团队',
  screeningRole: '筛选角色',
  modifyRole: '修改角色',
  adjustment: '调整参与项目',
  moveAllout: '全部移出',
  batchMoveAlloutTips: '移出成员后，这些成员创建的内容仍将保留，是否全部移出选中的成员？',
  moveoutTips: '移出成员后，该成员创建的内容仍将保留，是否移出该成员？',
  updateTeamRole: '修改团队角色',
  selectedMember: '已选择成员',
  roleDescription: '角色说明',
  adminDescription: '管理员：可管理编辑新增删除项目、巡检和团队成员；',
  inspectorDescription: '巡检员：可查看项目和巡检、筛查标记图片、编辑删除病害等；',
  visitorDescription: '查阅员：仅查看项目、病害权限。',
  updateProjectAuth: '调整参与项目权限',
  searchProjectName: '搜索项目名称',
  allProject: '全部项目',
  selectedProject: '已选择项目',
  inviteMember: '邀请成员',
  selectProjectRole: '选择项目权限',
  selectProjectRoleSub: '邀请加入此团队，并为其分配项目权限',
  onlyJoinTeam: '仅加入团队，暂时不分配项目权限',
  joinTeamProject: '加入团队，并获得部分项目权限',
  selectRole: '选择角色',
  selectRoleSub: '邀请外部人员加入团队后，成为何种角色',
  inviteTobe: '邀请成为',
  setPassword: '设置链接密码',
  randomPassword: '随机生成密码',
  customPassword: '自定义密码',
  linkValidity: '链接有效期',
  createLink: '生成链接',
  onlyCopyLink: '仅复制链接',
  copyLinkAndPassword: '复制链接和密码',
  teamFullTips: '团队成员已满员，请联系销售经理进行软件升级',
  inviteLink: '邀请链接',
  linkPassword: '链接密码',
  expired: '已失效',
  effective: '生效中',
  copySuccess: '复制成功',
  copyFail: '复制失败，链接已失效，请重新生成',
  teamName: '团队名称',
  handover: '移交团队',
  handoverTips: '每个团队只能有1位所有者，移交团队请联系技术支持',
  updateTeamName: '修改团队名称',
  teamNamePlaceholder: '请输入团队名称',
  joinTeam: '加入团队',
  projectTypeManagement: '项目类型管理',
  projectType: '项目类型',
  deleteProjectTypeTips: '是否确定删除该项目类型？',
  uploadExceedVolume: '团队储存容量已上限，请进行升级',
  uploadBeAboutToVolumn: '团队储存容量即将达到上限，请进行升级',
  membershipFunction: '会员功能',
  membershipFunctionTitle: '会员特色功能开通情况',
  membershipFunctionName: '功能名称',
  membershipFunctionDesc: '功能描述',
  membershipFunctionEffectiveness: '有效性',
  membershipFunctionExpirationTime: '到期时间',
  membershipFunctionRenew:'会员功能续期',
  renewDialogTitle:'会员特色功能续期',
  renewDialogSubtitle:'会员特殊功能续期，请联系你所在地区的销售经理',
  notOpen:'未开通',
  expired:'已过期'
}
