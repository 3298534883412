export default {
  personalCenter: '个人中心',
  logout: '退出登录',
  name: '姓名',
  email: '邮箱',
  phone: '手机号',
  password: '密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  changePassword: '修改密码',
  unbinded: '未绑定',
  bind: '绑定',
  unbind: '解绑',
  wechat: '微信',
  unbindWechat: '解绑微信',
  unit: '所在单位/学校',
  job: '你的岗位',
  avatarTips: '支持jpg、png格式的图片，大小不超过3M',
  view: '预览',
  editAvatar: '修改头像',
  editName: '修改姓名',
  editPhone: '修改手机号',
  editEmail: '修改邮箱',
  emailVerify: '邮箱验证',
  newEmail: '新邮箱',
  emailVerifyCode: '邮箱验证码',
  emailVerifyCodePlaceholder: '请输入邮箱验证码',
  emailVerifyCodeTips: '验证码已发送到您的邮箱',
  passwordVerify: '密码验证',
  oldPasswordPlaceholder: '请输入旧密码',
  newPasswordPlaceholder: '请输入新密码',
  confirmPasswordPlaceholder: '请再次输入新密码',
  wechatBind: '微信绑定',
  scanQRCode: '微信扫一扫绑定',
  wechatTips: '后续支持微信扫码登录',
  editUnit: '修改单位/岗位',
  editNamePlaceholder: '请输入姓名',
  verifyPhone: '手机验证',
  smsVerifyCode: '短信验证码',
  smsPlaceholder: '请输入短信验证码',
  newPhoneNumber: '新手机号',
  newPhoneNumberPlaceholder: '请输入新手机号',
  errorPhoneNum: '请输入正确的手机号',
  currentPassword: '当前密码',
  currentPasswordPlaceholder: '请输入当前密码',
  setNewPassword: '设置新密码',
  confirmPassword: '确认新密码',
  passwordPlaceholder: '请输入密码',
  inUnit: '所在单位/学校',
  inUnitPlaceholder: '请输入所在单位或学校名称',
  inJob: '所在岗位（职业）',
  inJobPlaceholder: '请输入所在岗位（职业）',
  editSuccess: '修改成功',
  varifySuccess: '验证成功',
  avatarTypeTips: '只能上传jpg、png格式的图片',
  avatarSizeTips: '图片大小不能超过3M',
  avatarUploadFail: '头像上传失败',
  avatarUploadSuccess: '头像上传成功',
  reUpload: '重新上传',
  uploadAvatar: '上传头像',
  changeLanguage: '切换语言'
}

