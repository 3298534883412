/*
 * @Author: zhengqj
 * @Date: 2024-06-14 14:56:12
 * @LastEditors: zhengqj
 * @LastEditTime: 2024-06-14 15:05:42
 * @Description: 安全访问
 */

export function useDubggerDefense() {
  (() => {
    function block() {
      if (window.outerHeight - window.innerHeight > 200 || window.outerWidth - window.innerWidth > 200) {
        document.body.innerHTML = '检测到非法调试,请关闭后刷新重试!'
      }
      setInterval(() => {
        (function() {
          return false
        }
          ['constructor']('debugger')
          ['call']())
      }, 50)
    }
    try {
      block()
    } catch (err) {}
  })()
}

export function useBanShortcutKey() {
  document.onkeydown = function(e) {
    if (e.keyCode === 123) {
      // alert('F12审查元素已被禁用！')
      return false
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
      // alert('F12审查元素已被禁用！')
      return false
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
      // alert('F12审查元素已被禁用！')
      return false
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
      // alert('F12审查元素已被禁用！')
      return false
    }
    if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
      alert('查看源代码已被禁用！')
      return false
    }
  }
}
