export default {
  feedbackTitle: '欢迎提交你的使用反馈和建议',
  feedbackSubTitle: '您想反馈哪方面的问题？',
  suggest: '建议反馈',
  softwareBug: '软件问题',
  feedbackPlaceholderSuggest: '请输入你使用软件后的建议或想要的功能',
  feedbackPlaceholderSoftwareBug: '请输入你遇到的软件问题(BUG)',
  upgrade: '软件升级',
  recycleBin: '回收站'
}
